import { endpoints } from "config/endpoints.config"
import { action, Action, thunk, Thunk } from "easy-peasy"
import { CHANNEL_TYPES, InputType } from "lib/constants"
import { IGenericModel, generic } from "models/generic"
import { IContact, UtmCampaign } from "./contacts"
import { IMessageSchedule } from "./messageSchedules"
import { ISegment } from "./segments"

export type ChannelType = (typeof CHANNEL_TYPES)[keyof typeof CHANNEL_TYPES]

export interface FacebookAdCampaign {
  headline: string
  ctwa_clid: string
  image_url: string
  video_url: string
  source_id: string
  media_type: "audio" | "image" | "video"
  source_url: string
  thumbnail_url: string
  source_type: "ad"
  body?: string
  fbclid?: string
}

export interface IConversation {
  id: string
  createdAt: string
  name: string
  contact: IContact
  callId: string
  customerId: string
  finishedAt?: string
  avatar?: string
  email?: string
  extraData?: any
  groupId?: string
  botId: string
  hasNewMessages: boolean
  isGroup: boolean
  isChatBotActive: boolean
  isFinished: boolean
  isFromIntegration: boolean
  isNew: boolean
  isHidden?: boolean
  isDeleted?: boolean
  identifier: string
  customerLastMessageAt: string
  isBlocked: boolean
  isCreatedByActiveChat?: boolean
  operatorLastMessageAt?: string
  customFields?: Record<string, { value: string; type?: InputType }>
  lastMessage: {
    _id?: string
    idMessage: string
    id?: string
    callId?: string
    content?: string
    createdAt?: string
    externalId?: string
    isMedia?: boolean
    operatorId?: string
    type?: 0 | 1 | 2 | 3 | 4
    customerPlatformId?: string
    status?: number
    isSent?: boolean
  }
  lastMessageAt: string
  lastSeen: string
  location?: string
  newCustomer?: "0" | "1"
  newContact?: "0" | "1"
  operatorId?: string
  pendingAt?: string
  phone?: string
  platformId: ChannelType
  showPending: boolean
  starred: boolean
  tags: string[]
  workspacePlatformId: string
  expirationDate?: string
  referral?: UtmCampaign & FacebookAdCampaign
}

export interface DetailConversation {
  id: string
  workspacePlatformId: string
  platformId: string
  identifier: string
  customerId: string
  Customer: {
    id: string
    name: string
    avatar: string
    phone: string
  }
  Call: {
    id: string
    operatorId: string
    isFinished: boolean
    campaignId?: string
  }
}

export interface IConversationDetails {
  conversations: DetailConversation[]

  lastViewed: string
  enableMerge: boolean
  scheduledMessages: IMessageSchedule[]
}

export interface IConversationModel extends IGenericModel<IConversation> {
  customSegments: ISegment[]
  setCustomSegments: Action<IConversationModel, ISegment[]>
  saveTags: Thunk<IConversationModel, { id: string; tags: string[]; callId: string }>
}

export const conversations: IConversationModel = {
  customSegments: [],
  setCustomSegments: action((state, payload) => {
    state.customSegments = payload
  }),
  saveTags: thunk(async (actions, model) => {
    actions.patchLoading({
      saving: true,
    })
    console.log(model)
    actions.patchElementOnAll(model)
  }),
  ...generic(endpoints.INBOX),
}
