import { Logtail } from "@logtail/browser"
const lt = new Logtail("RNxWcHgJT4DafTsxr2M877MU")

let logCredentials: {
    user_id?: string
    user_email?: string
    workspace_id?: string
    workspace_name?: string
} = {}

export const syncLogCredentials = () => {
    try {
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : null
        const workspace = localStorage.getItem("workspace")
            ? JSON.parse(localStorage.getItem("workspace")!)
            : null
        logCredentials = {
            user_id: "USER_" + user.id,
            user_email: "USER_" + user.email,
            workspace_id: "WS_" + workspace.id,
            workspace_name: "WS_" + workspace.workspace,
        }
    } catch (error) {
        logCredentials = {
            user_id: "USER_",
            user_email: "USER_",
            workspace_id: "WS_",
            workspace_name: "WS_",
        }
    }
}

export const logtail = {
    info: (message: string, opts?: any) => {
        return lt.info(message, { ...logCredentials, ...opts })
    },
    error: (message: string, opts?: any) => {
        return lt.error(message, { ...logCredentials, ...opts })
    },
    warn: (message: string, opts?: any) => {
        return lt.warn(message, { ...logCredentials, ...opts })
    },
    debug: (message: string, opts?: any) => {
        return lt.debug(message, { ...logCredentials, ...opts })
    },
}
