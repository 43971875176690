import { endpoints } from "config/endpoints.config"
import { AuthorType } from "lib/constants"
import { GenericResource, IGenericModel, generic } from "models/generic"

export enum TaskType {
  TASK = "task",
  CALL = "call",
  MEETING = "meeting",
  DEADLINE = "deadline",
  MAIL = "mail",
  LUNCH = "lunch",
  INIT_BOT = "init-bot",
}

export interface Task extends GenericResource {
  type: TaskType
  name: string
  operatorId: string
  authorId: number | string
  authorType: AuthorType
  description: string
  attachments: string[]
  from: string
  to: string
  done: boolean
  autoExec: boolean
  required: boolean
  notifyMinutesBefore: number
  day: number

  contact: {
    id: string
    name: string
    avatar: string
  }
  deal: {
    id: string
    name: string
    pipelineId: string
  }
  conversationId: number

  fromDate: string
  toDate: string
  fromTime: string
  toTime: string
  minutesDuration?: number

  context?: {
    automationId?: string
    cadenceId?: string
    botId?: any
  }
}

export const tasks: IGenericModel<Task> = {
  ...generic(endpoints.TASKS),
}
