import { IConditionType } from "components-old/forms/ConditionsInput"
import { IAsset } from "components-old/ui/FileCard"
import { endpoints } from "config/endpoints.config"
import { action, Action } from "easy-peasy"
import { Operators } from "lib/constants"
import { IGenericModel, generic } from "models/generic"
import { Elements } from "react-flow-renderer"
import { Deal } from "./deals"

export type IBotActionType =
  | "start"
  | "message"
  | "attachment"
  | "data_input"
  | "set_field"
  | "add_tags"
  | "crm"
  | "delay"
  | "assign"
  | "notify"
  | "integration"
  | "menu"
  | "decision"
  | "end"
  | "keyword"
  | "llm"
  | "deal"

export const botInteractiveTypes = ["data_input", "menu", "llm", "delay"]

// export interface IBotAction extends INodeData {
//   id?: string
//   guid: string
//   type: IBotActionType
//   source?: string
//   target?: string
//   targets?: {
//     sourceHandle: string
//     target: string
//   }[]
// }

export interface IBotActionFinal extends INodeDataAction {
  id?: string
  type: IBotActionType
  targets?: {
    sourceHandle: string
    target: string
  }[]
}

export interface INodeDataAction {
  // Message
  text?: string
  isNote?: boolean
  wait_type?: "timeout" | "wait_message" | "wait_webhook"
  action?: string
  seconds?: number
  template: any
  generateTextFromPrompt?: boolean
  prompt?: string
  partialDeal?: Partial<Deal>
  contact?: {
    name: string
    phone: string
    avatar: string
    id: string
  }

  // LLM
  knowledgeBaseId?: string
  exit_options?: {
    situation: string
    type: "direct" | "await_answer"
  }[]
  once?: boolean
  customPrompt?: string
  customPromptText?: string
  temperature?: number
  useLastClientMessage?: boolean

  // Attachment
  file?: IAsset

  // Input
  input_field_id?: string
  input_options?: {
    name: string
    value: string | number
  }[]
  input_validate?: boolean
  input_validate_text?: string
  hide_options?: boolean

  // Set field
  set_field_id?: string
  set_field_value?: string

  // Set tags
  add_tag_ids?: string[]
  remove_tag_ids?: string[]

  // Assign
  assign_type?: "agent" | "group" | "assignmentRule"
  assign_to_agent_id?: string
  assign_to_group_id?: string
  assign_to_assignment_rule_id?: string

  // Notify
  notify_by_channel_id?: string
  notify_to_number?: string
  notify_to_email?: string

  // Integration
  integration_description?: string
  integration_json?: string
  script?: string
  scriptCode?: string

  // Menu
  menu_options?: {
    name: string
    value: string
  }[]
  menu_validate_text?: string

  // keywords
  keywords?: {
    value: string
  }[]

  // Conditions
  conditions?: {
    all: {
      field: IConditionType
      operator: Operators
      value: string | number | boolean
      jsonPath?: string
    }[]
    any: {
      field: IConditionType
      operator: Operators
      value: string | number | boolean
      jsonPath?: string
    }[]
  }
  multi_any_options: boolean

  similarity?: boolean
  similarity_rate?: number
  validation_type?: string
  mask?: string
  regex?: string

  // end
  jump_to_checkpoint?: boolean

  // integration
  code?: "run_code" | "custom"

  // deal
  name: string
  value: number
  pipelineId: string
  stageId: string
}

export interface INodeData {
  // Message
  // removeNode: (id: string) => void
  // editNode: (id: string) => void
  // setElements: any
  backgroundColor?: string
  action?: INodeDataAction
}

export interface IBot {
  id: string
  name: string
  color: string
  createdAt: string
  isActive: boolean
  channels: string[]
  businessHours: string[]

  // expire
  expirable: boolean
  expireHours: number
  expireMinutes: number
  expireBot: number
  expireMessage: string
  actions: IBotActionFinal[]
  elements: Elements
  password: string
  blockOnlyIntegrations?: boolean
}

export interface IBotModel extends IGenericModel<IBot> {
  importedBot?: Partial<IBot>
  setImportedBot: Action<IBotModel, IBot | undefined>
}

export const bots: IBotModel = {
  setImportedBot: action((state, payload) => {
    state.importedBot = payload
  }),

  ...generic(endpoints.BOT),
}
