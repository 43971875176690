import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from "store"
import AppRouter from "./AppRouter"
import { RecoilRoot } from "recoil"
import { StoreProvider } from "easy-peasy"
import { store } from "hooks"
import { ConfigProvider } from "antd"
import locale from "antd/es/locale/pt_BR"

import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import "antd/dist/antd.css"
import "./styles/index.css"
import "./styles/sass/index.scss"
import "./styles/tailwind.css"
import "config/error.config"
import { logtail } from "lib/logtail"

ReactDOM.render(
  // <React.StrictMode>
  <StoreProvider store={store}>
    <RecoilRoot>
      <Provider>
        <Router>
          <ConfigProvider locale={locale}>
            <AppRouter />
          </ConfigProvider>
        </Router>
      </Provider>
    </RecoilRoot>
  </StoreProvider>,
  // </React.StrictMode>,
  document.getElementById("root"),
)

const WS_ID_MONITORING = "1684"

window.onerror = (message, source, lineno, colno, error) => {
  try {
    const wsString = localStorage.getItem("workspace")
    let ws

    if (wsString) {
      ws = JSON.parse(wsString)
      if (ws.workspaceId == WS_ID_MONITORING) {
        logtail.error("Unhandled Error", {
          message,
          source,
          lineno,
          colno,
          error: error?.toString(),
        })
      }
    }
  } catch (e) {
    console.log(e)
  }
}

window.onunhandledrejection = (event) => {
  try {
    const wsString = localStorage.getItem("workspace")
    let ws

    if (wsString) {
      ws = JSON.parse(wsString)
      if (ws.workspaceId == WS_ID_MONITORING) {
        logtail.error("Unhandled Promise Rejection", {
          reason: event.reason?.toString(),
        })
      }
    }
  } catch (e) {
    console.log(e)
  }
}
