import { FilterState } from "components-old/forms/FiltersInput"
import { endpoints } from "config/endpoints.config"
import { GenericResource, IGenericModel, generic } from "models/generic"
import { Product } from "models/products"
import { IMember } from "models/members"
import { IGroup } from "models/groups"
import { ITag } from "models/tags"
import { Pipeline } from "models/pipelines"
import moment from "moment"
import { formatterCurrency } from "lib/masks"
import dayjs from "dayjs"

// ██████╗ ███████╗ █████╗ ██╗
// ██╔══██╗██╔════╝██╔══██╗██║
// ██║  ██║█████╗  ███████║██║
// ██║  ██║██╔══╝  ██╔══██║██║
// ██████╔╝███████╗██║  ██║███████╗
// ╚═════╝ ╚══════╝╚═╝  ╚═╝╚══════╝
//
export enum DealMetric {
  COUNT = "count",
  VALUE = "value",
  TIME_TO_DONE = "time_to_done",
}

export enum DashboardDealGroupBy {
  DEAL_PIPELINE = "deal_pipeline",
  DEAL_STAGE = "deal_stage",
  OPERATOR_ID = "operatorId",
  GROUP_ID = "groupId",
  CREATED_AT = "createdAt",
  PRODUCTS = "products",
  TAGS = "tags",
  LOST_REASON = "lostReason",
  STATUS = "status",
  AUTHOR_TYPE = "authorType",
  LAST_STAGE_CHANGED = "lastStageChanged",
  WON_LOST_DATE = "wonLostDate",
}

const dealMetrics = [
  {
    id: DealMetric.COUNT,
    label: "Por quantidade",
    tooltipValue: "Negócios",
    type: "number",
  },
  {
    id: DealMetric.VALUE,
    label: "Por valor",
    tooltipValue: "Valor",
    hasNumberOperations: true,
    type: "currency",
  },
  // {
  //   id: "time_to_done",
  //   label: "Tempo até ganhar ou perder o negócio",
  //   hasNumberOperations: true,
  // },
]

export enum FilledType {
  Pipeline = "pipeline",
  Operator = "operator",
  Group = "group",
  Product = "product",
  Tag = "tag",
}

const dealGroupBy = [
  {
    id: "createdAt",
    label: "Data de criação",
    isDate: true,
  },
  {
    id: "wonLostDate",
    label: "Data de ganho/perdido",
    isDate: true,
  },
  {
    id: "deal_pipeline",
    label: "Funil",
    filledType: FilledType.Pipeline,
  },
  {
    id: "deal_stage",
    label: "Etapa",
  },
  {
    id: "operatorId",
    label: "Operador",
    filledType: FilledType.Operator,
  },
  {
    id: "groupId",
    label: "Grupo",
    filledType: FilledType.Group,
  },
  {
    id: "products",
    label: "Produto",
    filledType: FilledType.Product,
  },
  {
    id: "tags",
    label: "Tag",
    filledType: FilledType.Tag,
  },
  {
    id: "lostReason",
    label: "Motivo da perda",
  },
  {
    id: "status",
    label: "Status",
  },
  // {
  //   id: "authorType",
  //   label: "Tipo de autor",
  // },
  // {
  //   id: "lastStageChanged",
  //   label: "Tempo desde última mudança de etapa",
  // },
]

// ███████╗███████╗███████╗███████╗██╗ ██████╗ ███╗   ██╗
// ██╔════╝██╔════╝██╔════╝██╔════╝██║██╔═══██╗████╗  ██║
// ███████╗█████╗  ███████╗███████╗██║██║   ██║██╔██╗ ██║
// ╚════██║██╔══╝  ╚════██║╚════██║██║██║   ██║██║╚██╗██║
// ███████║███████╗███████║███████║██║╚██████╔╝██║ ╚████║
// ╚══════╝╚══════╝╚══════╝╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝
//

export enum DashboardSessionGroupBy {
  CREATED_AT = "call.createdAt",
  FINISHED_AT = "call.finishedAt",
  PENDING_AT = "conversation.pendingAt",
  OPERATOR_ID = "call.operatorId",
  GROUP_ID = "call.groupId",
  TAGS = "tags",
}

export enum SessionMetric {
  COUNT = "count",
  MESSAGES_COUNT = "messages-count",
  MESSAGES_BOT_COUNT = "messages-bot-count",
  MESSAGES_CONTACT_COUNT = "messages-contact-count",
  MESSAGES_OPERATOR_COUNT = "messages-operator-count",
  MESSAGES_ASSIGNED_COUNT = "messages-assigned-count",
}

const sessionMetrics = [
  {
    id: SessionMetric.COUNT,
    label: "Por quantidade",
    type: "number",
  },
  {
    id: SessionMetric.MESSAGES_COUNT,
    label: "Por quantidade de mensagens",
    type: "number",
  },
  {
    id: SessionMetric.MESSAGES_BOT_COUNT,
    label: "Por quantidade de mensagens de bot",
    type: "number",
  },
  {
    id: SessionMetric.MESSAGES_CONTACT_COUNT,
    label: "Por quantidade de mensagens de contato",
    type: "number",
  },
  {
    id: SessionMetric.MESSAGES_OPERATOR_COUNT,
    label: "Por quantidade de mensagens de operador",
    type: "number",
  },
  {
    id: SessionMetric.MESSAGES_ASSIGNED_COUNT,
    label: "Por quantidade de mensagens do operador atribuído",
    type: "number",
  },
]

const sessionGroupBy = [
  {
    id: DashboardSessionGroupBy.CREATED_AT,
    label: "Data de criação",
    isDate: true,
  },
  {
    id: DashboardSessionGroupBy.FINISHED_AT,
    label: "Data de resolução",
    isDate: true,
  },
  {
    id: DashboardSessionGroupBy.PENDING_AT,
    label: "Data de pendência",
    isDate: true,
  },
  {
    id: DashboardSessionGroupBy.OPERATOR_ID,
    label: "Operador",
    filledType: FilledType.Operator,
  },
  {
    id: DashboardSessionGroupBy.GROUP_ID,
    label: "Grupo",
    filledType: FilledType.Group,
  },
  {
    id: DashboardSessionGroupBy.TAGS,
    label: "Tag",
    filledType: FilledType.Tag,
  },
]

// ██████╗ ███████╗███████╗███████╗
// ██╔══██╗██╔════╝██╔════╝██╔════╝
// ██║  ██║█████╗  █████╗  ███████╗
// ██║  ██║██╔══╝  ██╔══╝  ╚════██║
// ██████╔╝███████╗██║     ███████║
// ╚═════╝ ╚══════╝╚═╝     ╚══════╝
//

export const metricNumberOperations = [
  {
    label: "Soma",
    value: "sum",
  },
  {
    label: "Média",
    value: "avg",
  },
  {
    label: "Mínimo",
    value: "min",
  },
  {
    label: "Máximo",
    value: "max",
  },
  // {
  //   label: "Contagem",
  //   value: "count",
  // },
]

export const getLabelFromGroupType = (
  text: any,
  report?: DashboardReport,
  context?: {
    products: Product[]
    members: IMember[]
    groups: IGroup[]
    tags: ITag[]
    pipelines: Pipeline[]
    fullDate?: boolean
    isDate?: boolean
  },
) => {
  if (report?.groupBy === "status") {
    switch (text) {
      case "won":
        return "Ganho"
      case "lost":
        return "Perdido"
      case "open":
        return "Em andamento"
      default:
        return text
    }
  }
  if (report?.groupBy === "products") {
    const product = context?.products.find((product) => product.id == text)
    return product?.name || "Sem produto"
  }
  if (report?.groupBy === "operatorId" || report?.groupBy === "call.operatorId") {
    const member = context?.members.find((member) => member.id == text)
    return member?.name || "Sem responsável"
  }
  if (report?.groupBy === "groupId" || report?.groupBy === "call.groupId") {
    const group = context?.groups.find((group) => group.id == text)
    return group?.name || "Sem grupo"
  }
  if (report?.groupBy === "tags") {
    const tag = context?.tags.find((tag) => tag.id == text)
    return tag?.name || text
  }
  if (report?.groupBy === DashboardDealGroupBy.DEAL_PIPELINE) {
    const pipeline = context?.pipelines.find((pipeline) => pipeline.id == text)
    return pipeline?.name || text
  }
  if (report?.groupBy === DashboardDealGroupBy.DEAL_STAGE) {
    const pipeline = context?.pipelines.find((pipeline) => pipeline.stages?.find((stage) => stage.id == text))
    const stage = pipeline?.stages?.find((stage) => stage.id == text)
    return stage?.name || text
  }
  if (context?.isDate) {
    // Significa que é uma hora
    if (text?.includes("##")) {
      return text.split("##")[1]
    }

    const date = dayjs(text, "YYYY-MM-DD").locale("pt-br")

    if (report?.period === "week") {
      return context?.fullDate ? date.format("ddd DD/MM/YYYY").toUpperCase() : date.format("ddd DD/MM").toUpperCase()
    }
    return context?.fullDate ? date.format("DD/MM/YYYY") : date.format("DD/MM")
  }

  return text
}

const mountReportTotalSingleQuery = (report: DashboardReport, patches: Partial<DashboardReport>, name: string) => {
  return {
    [`${report.id}_total_${name}`]: {
      ...report,
      groupBy: "",
      id: `${report.id}_total_${name}`,
      view: DashboardView.SINGLE_METRIC,
      ...patches,
    },
  } as Record<string, DashboardReport>
}

export const dashboardDef = {
  deals: {
    metrics: dealMetrics,
    groupBys: dealGroupBy,
    periodMetrics: [
      {
        value: "createdAt",
        label: "Data da criação",
      },
      {
        value: "wonLostDate",
        label: "Ganho/perdido em",
      },
      {
        value: "dueDate",
        label: "Previsão de fechamento",
      },
    ],
    buildReportTotalsQuery: (report: DashboardReport): Record<string, DashboardReport> => {
      return {
        ...mountReportTotalSingleQuery(report, { metric: DealMetric.COUNT }, "count"),
        ...mountReportTotalSingleQuery(report, { metric: DealMetric.VALUE }, "value"),
        ...mountReportTotalSingleQuery(report, { metric: DealMetric.VALUE, metricOperation: DashboardMetricOperation.AVG }, "ticket"),
      }
    },
    totals: [
      {
        title: "Total",
        tip: "Total de negócios",
        key: "count",
        getValueFormatted: (result: DashboardReportResult[]) => {
          return result?.[0]?.value
        },
      },
      {
        title: "Valor total",
        tip: "Soma do valor de todos os negócios",
        key: "value",
        getValueFormatted: (result: DashboardReportResult[]) => {
          return formatterCurrency(result?.[0]?.value)
        },
        color: "#059669",
      },
      {
        title: "Ticket médio",
        tip: "Valor médio por negócio desconsiderando negócios que não tem preço",
        key: "ticket",
        getValueFormatted: (result: DashboardReportResult[]) => {
          return formatterCurrency(result?.[0]?.value)
        },
      },
    ],
    defaultPeriodMetric: "createdAt" as DashboardGroupBy,
  },
  sessions: {
    metrics: sessionMetrics,
    groupBys: sessionGroupBy,
    defaultPeriodMetric: "call.createdAt" as DashboardGroupBy,
    buildReportTotalsQuery: (report: DashboardReport): Record<string, DashboardReport> => {
      return {
        ...mountReportTotalSingleQuery(report, { metric: DealMetric.COUNT }, "count"),
        ...mountReportTotalSingleQuery(report, { metric: DealMetric.COUNT, extraParams: { assigned: ["unassigned"] } }, "not_assigned"),
        ...mountReportTotalSingleQuery(report, { metric: DealMetric.COUNT, extraParams: { assigned: ["unassigned"] } }, "finished"),
        // ...mountReportTotalSingleQuery(report, { metric: DealMetric.COUNT, extraParams: { waiting: true } }, "waiting"),
        // ...mountReportTotalSingleQuery(report, { metric: DealMetric.COUNT, extraParams: { bots: true } }, "bots"),
        // ...mountReportTotalSingleQuery(report, { metric: DealMetric.COUNT, extraParams: { tmeExists: true } }, "tme"),
        // ...mountReportTotalSingleQuery(
        //   report,
        //   { metric: DealMetric.COUNT, extraParams: { isFinished: true, onlyFinishedAt: true, isReopened: false, tmaExists: true } },
        //   "tma",
        // ),
        // ...mountReportTotalSingleQuery(report, { metric: DealMetric.COUNT, extraParams: { onlyCreatedAt: true } }, "new_sessions"),
        ...mountReportTotalSingleQuery(report, { metric: DealMetric.COUNT, extraParams: { isNewContact: true } }, "leads"),
      }
    },
    totals: [
      {
        title: "Total",
        tip: "Total de sessões",
        key: "count",
        getValueFormatted: (result: DashboardReportResult[]) => {
          return result?.[0]?.value
        },
      },
      {
        title: "Não atribuído",
        key: "not_assigned",
        getValueFormatted: (result: DashboardReportResult[]) => {
          return result?.[0]?.value
        },
        color: "#e74c3c",
        tip: "Conversas que não tem nenhum agente atribuído",
      },
      {
        title: "Resolvidos",
        key: "finished",
        getValueFormatted: (result: DashboardReportResult[]) => {
          return result?.[0]?.value
        },
        tip: "Sessões que estão resolvidas",
      },
      // {
      //   title: "TME",
      //   key: "tme",
      //   getValueFormatted: (result: DashboardReportResult[]) => {
      //     return result?.[0]?.value
      //   },
      //   tip: "Tempo médio de espera (Tempo para primeira resposta do agente)",
      // },
      // {
      //   title: "TMA",
      //   key: "tma",
      //   getValueFormatted: (result: DashboardReportResult[]) => {
      //     return result?.[0]?.value
      //   },
      //   tip: "Tempo médio de atendimento (Tempo para resolução da conversa)",
      // },
      {
        title: "Leads",
        key: "leads",
        getValueFormatted: (result: DashboardReportResult[]) => {
          return result?.[0]?.value
        },
        tip: "Sessões de novos contatos criadas no período selecionado",
      },
      // {
      //   title: "Em espera",
      //   key: "waiting",
      //   getValueFormatted: (result: DashboardReportResult[]) => {
      //     return result?.[0]?.value
      //   },
      //   color: "#e74c3c",
      //   tip: "Em espera de atendimento. Conversa que o atendente ainda não mandou a primeira mensagem na sessão.",
      // },
      // {
      //   title: "Bots",
      //   key: "bots",
      //   getValueFormatted: (result: DashboardReportResult[]) => {
      //     return result?.[0]?.value
      //   },
      //   tip: "Conversas que o Bot está atendendo",
      // },
    ],
    periodMetrics: [
      {
        value: "call.createdAt",
        label: "Data da criação",
      },
      {
        value: "call.finishedAt",
        label: "Data de resolução",
      },
    ],
  },
}

export interface DashboardLayout {
  i: string
  x: number
  y: number
  w: number
  h: number
}

export enum DashboardDataSource {
  DEALS = "deals",
  SESSIONS = "sessions",
}

export enum DashboardPeriod {
  TODAY = "today",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
  LAST_YEAR = "last_year",
  LAST_MONTH = "last_month",
  LAST_3_MONTHS = "last_3_months",
  LAST_1H = "last_1h",
  LAST_3H = "last_3h",
  LAST_24H = "last_24h",
  CUSTOM = "custom",
}

export enum DashboardMetricOperation {
  SUM = "sum",
  AVG = "avg",
  MIN = "min",
  MAX = "max",
  COUNT = "count",
}

export type DashboardGroupBy = DashboardDealGroupBy | DashboardSessionGroupBy | ""
export type DashboardMetric = DealMetric | SessionMetric

export enum DashboardView {
  SINGLE_METRIC = "single_metric",
  BAR = "bar",
  PIE = "pie",
  LINE = "line",
}

// ███████╗████████╗ ██████╗ ██████╗ ███████╗
// ██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝
// ███████╗   ██║   ██║   ██║██████╔╝█████╗
// ╚════██║   ██║   ██║   ██║██╔══██╗██╔══╝
// ███████║   ██║   ╚██████╔╝██║  ██║███████╗
// ╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝
//
export interface Dashboard extends GenericResource {
  name: string
  description: string
  layout?: DashboardLayout[]
  // extremeDates?: {
  //   // e.g: deal-createdAt
  //   [key: string]: {
  //     oldestDate?: Date
  //     mostRecentDate?: Date
  //   }
  // }

  dashboardCards?: {
    [key: string]: DashboardReport
  }
}

export interface DashboardReport {
  id: string
  name: string
  dataSource?: DashboardDataSource
  metric?: DashboardMetric
  metricOperation?: DashboardMetricOperation
  groupBy?: DashboardGroupBy
  view?: DashboardView
  showAxisLabel?: DashboardView
  showZeroes?: DashboardView
  period?: string
  periodMetric?: string
  periodFromTo?: any
  from?: string
  to?: string
  conditions?: FilterState
  extraParams?: Record<string, any>
}

export interface DashboardReportResult {
  _id?: null | string | number
  group?: null | string | number
  value: number
  category?: string
}

export const dashboards: IGenericModel<Dashboard> = {
  ...generic(endpoints.DASHBOARDS),
}
