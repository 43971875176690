import { endpoints } from "config/endpoints.config"
import { Privacy } from "lib/constants"
import { GenericResource, IGenericModel, generic } from "models/generic"
import { Task } from "./tasks"

export interface Cadence {
  stageId: string
  task?: Task
  id?: string
}

export interface PipelineStage {
  id: string
  name: string
  color: string
  requireds?: string[]
}

export interface PipelineRule {
  ruleId: string
  max?: number
  stageId?: string
  selectedCustomFields?: string[]
}

export interface Pipeline extends GenericResource {
  name: string
  position: number
  stages?: PipelineStage[]
  privacy?: {
    type: Privacy
    groupIds?: string[]
  }
  lostReasons?: string[]
  requiredCustomFieldIds?: string[]
  authorId?: number
  cadence?: Cadence[]
  rules?: PipelineRule[]
}

export const pipelines: IGenericModel<Pipeline> = {
  ...generic(endpoints.PIPELINES),
}
