import React, { useState, useMemo, useCallback, useEffect } from "react"
import req from "lib/req"
import config from "config"
import { stores } from "store"
import { differenceInCalendarDays } from "date-fns"
import request from "lib/request"
import { useSetRecoilState } from "recoil"
import { appLoaderAtom } from "../atoms/app.atom"
import toast from "lib/toast"
import { v2Socket } from "hooks/useSocketV2"
import { useStoreActions } from "hooks"

// import { urlBase64ToUint8Array } from "serviceWorkerRegistration"
const wsChannel = new BroadcastChannel("workspaceChangedChannel")
wsChannel.onmessage = function (event) {
  const value = event.data
  console.log("wsChannel", event.data)
  if (value == "workspace-changed") {
    window.location.reload(false)
  }
}

const defaultUser = { isRecovering: true, workspaces: [{}] }
function SessionStore() {
  const [user, setUser] = useState(defaultUser)
  const [workspace, setWorkspace] = useState({})
  const [helpers, setHelpers] = useState([])
  const setAppLoader = useSetRecoilState(appLoaderAtom)
  const usersStore = useStoreActions((actions) => actions.users)

  // const isExpired = useMemo(
  //     () =>
  //         (workspace || {}).subscription &&
  //         new Date(workspace.subscription.expiresAt) < new Date(),
  //     [workspace?.subscription]
  // )
  const isExpired = useMemo(() => {
    if (!workspace?.subscription) return false

    const expireDate = new Date(workspace.subscription.expiresAt)
    expireDate.setDate(expireDate.getDate() + 3)
    // add 3 days to expire date

    return expireDate < new Date()
  }, [workspace?.subscription])

  const isExpiring = useMemo(() => {
    if (!workspace?.subscription) return false
    const expireDate = new Date(workspace.subscription.expiresAt)
    expireDate.setDate(expireDate.getDate() + 3)

    if (!isExpired) {
      const diffDays = differenceInCalendarDays(expireDate, new Date())

      if (diffDays >= 0 && diffDays <= 2) {
        return diffDays
      }
    }
    return false
  }, [isExpired, workspace?.subscription])

  const saveUser = useCallback(async function (user, inspectingUser, inspectingType) {
    // console.log("USER", user)
    setUser({
      ...user,
      inspectingUser: inspectingUser || "",
      inspectingType: inspectingType || "",
    })
    localStorage.setItem("user", JSON.stringify(user))
  }, [])

  const saveWorkspace = useCallback(async function (_workspace) {
    const workspace = { ..._workspace, id: _workspace.id || _workspace.workspaceId }
    if (!workspace || typeof workspace !== "object") {
      toast.error("Erro ao trocar de ambiente")
      return
    }
    setWorkspace(workspace)
    localStorage.setItem("workspace", JSON.stringify(workspace))
    localStorage.setItem("nanui-token", workspace.token)
    localStorage.setItem("nanui-token-renewal", workspace.renewalToken)
  }, [])

  const onLogin = useCallback(
    async function (response, workspaceId, inspectingUser, inspectingType) {
      if (!response.error) {
        if (workspaceId) {
          const workspace = response.workspaces.find((w) => w.workspaceId == workspaceId)
          saveWorkspace(workspace || response.workspaces[0])
        } else {
          saveWorkspace(response.workspaces[0])
        }
        saveUser(response, inspectingUser, inspectingType)

        wsChannel.postMessage("workspace-changed")

        return response
      }

      wsChannel.postMessage("workspace-changed")
      return false
    },
    [saveUser, saveWorkspace],
  )
  const login = useCallback(
    async function (data) {
      return request.post(config.endpoints.LOGIN, data).then((response) => {
        onLogin(response?.data, data?.workspaceId, data?.inspectingUser, data?.inspectingType)
        return response?.data
      })
      // const response = await req(config.endpoints.LOGIN, {
      //     data,
      //     method: "post",
      //     loader: "login",
      // })
      // return onLogin(response)
    },
    [onLogin],
  )

  const loginInspect = useCallback(
    async function (data, isAdmin) {
      return request
        .post(isAdmin ? config.endpoints.LOGIN_ADMIN_INSPECT : config.endpoints.LOGIN_PARTNER_INSPECT, data, null, {
          headers: {
            Authorization: data?.tk,
          },
        })
        .then((response) => {
          onLogin(response?.data, data?.workspaceId, data?.inspectingUser, data?.inspectingType)
          return response?.data
        })
      // const response = await req(config.endpoints.LOGIN, {
      //     data,
      //     method: "post",
      //     loader: "login",
      // })
      // return onLogin(response)
    },
    [onLogin],
  )

  const renewLogin = useCallback(
    async function (data) {
      return request.post(config.endpoints.RENEW_TOKEN, data).then((response) => {
        onLogin(response?.data, data?.workspaceId, data?.inspectingUser, data?.inspectingType)
        return response?.data
      })
    },
    [onLogin],
  )

  const changePassword = useCallback(async function (payload) {
    await req(config.endpoints.CHANGE_PASSWORD, {
      ...payload,
      method: "put",
      loader: "put/session",
    })
    return false
  }, [])

  const sendForgotEmail = useCallback(async function (payload) {
    await req(config.endpoints.FORGOT, {
      ...payload,
      method: "put",
      loader: "put/session",
    })
    return false
  }, [])

  const resetPassword = useCallback(async function (payload) {
    await req(config.endpoints.RESET, {
      ...payload,
      method: "put",
      loader: "put/session",
    })
    return false
  }, [])

  // From Join
  const completeRegister = useCallback(async function (payload) {
    const data = await req(config.endpoints.COMPLETE_REGISTER, {
      ...payload,
      method: "post",
      loader: "register",
    })
    return data
  }, [])

  // From resendemail
  const resendEmail = useCallback(async function () {
    const data = await req(config.endpoints.RESEND_AGENT_EMAIL, {
      method: "get",
    })
    return data
  }, [])

  // From confirm email
  const confirmEmail = useCallback(async function (payload) {
    const data = await req(config.endpoints.CONFIRM_AGENT_EMAIL, {
      ...payload,
      method: "post",
    })
    return data
  }, [])

  const updateProfile = useCallback(async function (payload, options) {
    try {
      const user = JSON.parse(localStorage.getItem("user"))
      if (options?.saveBefore) {
        // const user = JSON.parse(localStorage.getItem("user"))
        const newUser = { ...user, ...payload }
        setUser(newUser)
        localStorage.setItem("user", JSON.stringify(newUser))
      }

      const { data } = await request.put(config.endpoints.UPDATE_PROFILE, payload)
      if (data && data.email) {
        // const { workspaces, ...dataWithoutWorkspaces } = data
        // const workspacesWithoutToken = data.workspaces?.map((ws) => {
        //   const { token, ...wsWithoutToken } = ws
        //   return wsWithoutToken
        // })

        const finalUser = { ...data, workspaces: user.workspaces }
        setUser(finalUser)
        localStorage.setItem("user", JSON.stringify(finalUser))
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  const patchWorkspace = useCallback(
    async function (payload) {
      setUser((user) => {
        const oldWorkspaceIndex = user.workspaces.findIndex((workspace) => workspace.workspaceId == payload.workspaceId)
        const updatedWorkspace = {
          ...user.workspaces[oldWorkspaceIndex],
          ...payload,
        }
        user.workspaces[oldWorkspaceIndex] = updatedWorkspace
        const patchedUser = {
          ...user,
          // workspaces: [updatedWorkspace, ...user.workspaces]
        }
        localStorage.setItem("user", JSON.stringify(patchedUser))
        saveWorkspace(patchedUser.workspaces[oldWorkspaceIndex])
        return patchedUser
      })
    },
    [saveWorkspace],
  )

  const toggleAvaliable = useCallback(
    async function (payload) {
      patchWorkspace({ ...workspace, ...payload })
      return request.put(config.endpoints.UPDATE_PROFILE, payload).then((response) => {
        // if (payload.availabilityChangeReason) {
        //   usersStore.patchElementOnAll({
        //     id: user.id,
        //     availabilityChangeReason: payload.availabilityChangeReason,
        //   })
        // }
        patchWorkspace(
          response.data.workspaces
            ?.map((ws) => {
              const { token, ...wsWithoutToken } = ws
              return wsWithoutToken
            })
            .find((ws) => ws.workspaceId == workspace.workspaceId),
        )
      })
    },
    [patchWorkspace, workspace],
  )

  const updateWorkspace = useCallback(
    async function (payload) {
      const response = await req(config.endpoints.WORKSPACE, {
        ...payload,
        method: "put",
        loader: "put/workspace",
      })
      if (response) {
        patchWorkspace(response)
      }
    },
    [patchWorkspace],
  )

  const getWorkspace = useCallback(
    async function (payload) {
      const response = await req(config.endpoints.WORKSPACE, {
        ...payload,
        method: "get",
        loader: "get/workspace",
      })
      if (response) {
        patchWorkspace({ ...workspace, ...response })
      }
    },
    [patchWorkspace, workspace],
  )

  const getJoinInfo = useCallback(async function (payload) {
    const data = await req(config.endpoints.JOIN_WORKSPACE, payload)
    return data
  }, [])

  const joinWorkspace = useCallback(async function (payload) {
    const data = await req(config.endpoints.JOIN_WORKSPACE, {
      ...payload,
      method: "post",
    })
    return data
  }, [])

  const changeWorkspace = useCallback(
    async function (workspace, history, opts) {
      setAppLoader(true)
      if (opts?.redirect) {
        localStorage.setItem("redirect", opts.redirect)
      }

      saveWorkspace(workspace)
      localStorage.setItem("changedWorkspace", { changedWorkspace: true })
      localStorage.removeItem("segments")

      wsChannel.postMessage("workspace-changed")

      // console.log("CHANGE WORKSPACE", history.location)
      // history.replace({search`/${history.location.search}&changedWorkspace=true`})
      // toast.info(
      //     <span>
      //         Você está agora no workspace{" "}
      //         <strong
      //             style={{
      //                 color: "var(--primary)",
      //             }}
      //         >
      //             {workspace.workspace}
      //         </strong>
      //     </span>,
      //     3,
      //     {
      //         key: "change-workspace",
      //     }
      // )
      window.location.reload(false)
    },
    [saveWorkspace, setAppLoader],
  )

  const createWorkspace = useCallback(
    async function (payload, history) {
      const data = await req(config.endpoints.WORKSPACE, {
        ...payload,
        method: "post",
      })
      if (!data.error || !data.errors) {
        saveWorkspace(data)
        saveUser({ ...user, workspaces: [...user.workspaces, data] })
        changeWorkspace(data, history)
      }
    },
    [changeWorkspace, saveUser, saveWorkspace, user],
  )

  const recoverHelpers = useCallback(function () {
    const localHelpers = JSON.parse(localStorage.getItem("helpers"))
    if (localHelpers) {
      setHelpers(localHelpers)
    }
  }, [])

  const logoutLite = useCallback(function (helper) {
    window?.ReactNativeWebView?.postMessage?.(
      JSON.stringify({
        type: "logout",
      }),
    )
    localStorage.removeItem("user")
    localStorage.removeItem("helpers")
    localStorage.removeItem("workspace")
    localStorage.removeItem("skin")
    localStorage.removeItem("push-subscription")
    localStorage.clear()
    setUser({})
    setHelpers([])
    setWorkspace({})
    stores.general.setActiveIntegration({})
  }, [])

  const logout = useCallback(
    function (dto = { notNotifyV0: false }) {
      // UNCOMMENT TO PUSH SUBSCRIPTION UNREGISTER 🤡🤡
      try {
        const pushSubscription = localStorage.getItem("push-subscription")
        const user = JSON.parse(localStorage.getItem("user"))

        v2Socket?.emit("logout")
        if (!dto.notNotifyV0) {
          request.post(config.endpoints.LOGOUT, {
            message: "Deslogado",
          })
        }
        if (pushSubscription) {
          request.delete(config.endpoints.SAVE_PUSH_SUBSCRIPTION, JSON.parse(pushSubscription)).then((response) => {
            // console.log(
            //     "🍅 Desinscrito no web push notification",
            //     pushSubscription,
            //     response?.data
            // )
            return response?.data
          })
        }
      } catch (e) {
        console.log("Erro ao logout", e)
      }

      logoutLite()
      setTimeout(() => {
        wsChannel.postMessage("workspace-changed")
        window.location.reload()
      }, 1000)
    },
    [logoutLite],
  )

  const recoverSession = useCallback(
    function () {
      try {
        recoverHelpers()
        const localUser = JSON.parse(localStorage.getItem("user"))
        const localWorkspace = JSON.parse(localStorage.getItem("workspace"))
        setUser(localUser || { workspaces: [{}] })
        setWorkspace(localWorkspace)
      } catch (e) {
        console.log("Erro ao recuperar sessão", e)
        logout()
      }
    },
    [logout, recoverHelpers],
  )

  const saveSeenHelpers = useCallback(
    function (helper) {
      setHelpers([...helpers, helper])
      localStorage.setItem("helpers", JSON.stringify(helpers))
    },
    [helpers],
  )

  return {
    user,
    workspace,
    helpers,

    // Actions
    login,
    saveUser,
    saveWorkspace,
    resendEmail,
    confirmEmail,
    patchWorkspace,
    logout,
    logoutLite,
    recoverSession,
    saveSeenHelpers,
    changePassword,
    completeRegister,
    sendForgotEmail,
    updateProfile,
    renewLogin,
    updateWorkspace,
    getWorkspace,
    getJoinInfo,
    joinWorkspace,
    changeWorkspace,
    createWorkspace,
    onLogin,
    isExpired,
    isExpiring,
    resetPassword,
    toggleAvaliable,
    loginInspect,
  }
}

export default SessionStore
