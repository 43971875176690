import {
    Button,
    Col,
    Drawer,
    Dropdown,
    Form,
    InputNumber,
    Menu,
    Rate,
    Row,
    Select,
    Table,
} from "antd"
import { ColumnsType, TablePaginationConfig } from "antd/lib/table"
import React, { useEffect, useMemo, useState } from "react"
import { formatCompleteDate, onCellClick } from "lib/helper"
import request from "lib/request"
import { endpoints } from "config/endpoints.config"
import Icon from "components-old/common/Icon"
import Chat from "pages/inbox/Chat"
import TableActionsDropdown from "components-old/common/TableActionsDropdown"
import MenuLink from "components-old/menu/MenuLink"
import _ from "lodash"
import cx from "classnames"

import settingsPageStyles from "components-old/common/SettingsPage/style.module.css"
import Tooltip from "components-old/Tooltip"
import BuySmsModal from "."
import { formatterCurrencyNew } from "lib/masks"
import { IPaginatedResponse } from "models/generic"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { modalLevel2Atom } from "atoms/app.atom"
import { IBuySMSTransaction } from "models/adminTransactions"

interface ISmsTransactionsProps {
    onClose: () => void
}

function SmsTransactions(props: ISmsTransactionsProps) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [transactions, setTransactions] = useState<IBuySMSTransaction[]>([])
    const setModalLevel2 = useSetRecoilState(modalLevel2Atom)

    // const [activeTransaction, setActiveTransaction] = useState<IBuySMSTransaction | null>()

    function getColumnProps() {
        return {
            onCell: (record: any) =>
                onCellClick(() => {
                    // setActiveTransaction(record)
                    setModalLevel2(
                        <BuySmsModal
                            transaction={record}
                            onBack={() => {
                                setModalLevel2(null)
                            }}
                        />
                    )
                }),
            className: "pointer",
        }
    }

    const columns: ColumnsType<IBuySMSTransaction> = useMemo(() => {
        return [
            {
                ...getColumnProps(),
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (value) => {
                    switch (value) {
                        case "Aprovada":
                            return (
                                <span>
                                    <i
                                        className="fas fa-check-circle"
                                        style={{ color: "#27ae60", marginRight: 10 }}
                                    ></i>
                                    {value}
                                </span>
                            )

                        default:
                            return (
                                <span>
                                    <i className="fal fa-clock" style={{ marginRight: 10 }}></i>
                                    {value}
                                </span>
                            )
                    }
                },
            },
            // {
            //     title: "Agente",
            //     dataIndex: "operatorName",
            //     key: "operatorName",
            // },
            {
                ...getColumnProps(),
                title: "Créditos",
                dataIndex: ["extraInfo", "quantity"],
                key: "quantity",
            },
            {
                ...getColumnProps(),
                title: "Valor",
                dataIndex: "cents",
                key: "cents",
                render: (value) => {
                    return formatterCurrencyNew(value / 100)
                },
            },
            {
                ...getColumnProps(),
                title: "Data",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (value) => {
                    return formatCompleteDate(value)
                },
            },
            {
                key: "action",
                fixed: "right",
                width: 60,
                render: (text, record) => (
                    <TableActionsDropdown>
                        <Menu
                            onClick={(e) => {
                                e.domEvent.stopPropagation()
                            }}
                        >
                            <Menu.Item
                                key="qrcode"
                                icon={<i className="fal fa-qrcode" />}
                                onClick={() => {
                                    setModalLevel2(
                                        <BuySmsModal
                                            transaction={record}
                                            onBack={() => {
                                                setModalLevel2(null)
                                            }}
                                        />
                                    )
                                }}
                            >
                                Ver QRCode
                            </Menu.Item>
                            {/* <Menu.Item key="edit">
                                <MenuLink
                                    iconName={"fal fa-arrow-up-right-from-square"}
                                    href={`https://go.kinbox.com.br/inbox/all/${record?.customerPlatformId}`}
                                >
                                    Abrir conversa
                                </MenuLink>
                            </Menu.Item> */}
                        </Menu>
                    </TableActionsDropdown>
                ),
            },
        ]
    }, [])

    useEffect(() => {
        setLoading(true)
        request
            .get<IPaginatedResponse<IBuySMSTransaction>>(endpoints?.SMS_TRANSACTIONS)
            .then((res) => {
                setTransactions(res.data.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <Drawer
            title={`Histórico de transações de SMS`}
            width={800}
            onClose={() => props.onClose()}
            visible
            bodyStyle={{ paddingBottom: 80 }}
            maskClosable
            style={{
                zIndex: 1001,
            }}
            closeIcon={<i className="fal fa-times" style={{ fontSize: 21 }} />}
            getContainer={false}
            className="drawer-table"
            // extra={
            //     <Dropdown
            //         trigger={["click"]}
            //         overlay={
            //             <Menu style={{ padding: 18, width: 300 }}>
            //                 <div>
            //                     <Form
            //                         onFinish={(model) => {
            //                             // setFilters(model)
            //                             setFilters({
            //                                 [model.ratingFilterKey]: model.ratingFilterValue,
            //                             })
            //                         }}
            //                         form={form}
            //                         hideRequiredMark
            //                         layout="vertical"
            //                         className={settingsPageStyles.panel_search}
            //                         // onValuesChange={(
            //                         //     changedValues,
            //                         //     allValues
            //                         // ) => {
            //                         //     setUnsaved(true)
            //                         // }}
            //                         initialValues={{
            //                             ratingFilterKey: "ratingLessThan",
            //                             ratingFilterValue: 3,
            //                         }}
            //                     >
            //                         <div
            //                             style={{
            //                                 display: "flex",
            //                                 flexDirection: "column",
            //                             }}
            //                         >
            //                             <Form.Item
            //                                 name="ratingFilterKey"
            //                                 label="Nota"
            //                                 style={{
            //                                     marginBottom: 4,
            //                                 }}
            //                             >
            //                                 <Select>
            //                                     <Select.Option value="rating">
            //                                         Igual a
            //                                     </Select.Option>
            //                                     <Select.Option value="ratingLessThan">
            //                                         Menor ou igual a
            //                                     </Select.Option>
            //                                     <Select.Option value="ratingGreaterThan">
            //                                         Maior ou igual a
            //                                     </Select.Option>
            //                                 </Select>
            //                             </Form.Item>
            //                             <Form.Item name="ratingFilterValue">
            //                                 <InputNumber style={{ width: "100%" }} />
            //                             </Form.Item>
            //                         </div>
            //                         <Row justify="end">
            //                             <Col>
            //                                 <Button type="primary" htmlType="submit">
            //                                     Filtrar
            //                                 </Button>
            //                             </Col>
            //                         </Row>
            //                     </Form>
            //                 </div>
            //             </Menu>
            //         }
            //     >
            //         <Button
            //             icon={<i className="far fa-sliders" />}
            //             style={{
            //                 color: "var(--content-primary)",
            //                 display: "flex",
            //                 alignItems: "center",
            //             }}
            //         >
            //             <span style={{ lineHeight: "15px" }}>Filtros</span>
            //         </Button>
            //     </Dropdown>
            // }
        >
            <Table
                loading={loading}
                columns={columns}
                dataSource={transactions}
                rowKey="_id"
                pagination={{
                    current: 1,
                    pageSize: 99999,
                    position: ["bottomCenter"],
                    hideOnSinglePage: true,
                    total: 0,
                    showQuickJumper: false,
                    showSizeChanger: false,
                    showLessItems: true,
                }}
            />
            {transactions?.length! > 90 && (
                <Row justify="center">
                    <Button
                        onClick={() => {
                            setLoading(true)
                            request
                                .get<IPaginatedResponse<IBuySMSTransaction>>(
                                    endpoints?.SMS_TRANSACTIONS,
                                    {
                                        params: {
                                            limit: 500,
                                            offset: transactions?.length,
                                        },
                                    }
                                )
                                .then(({ data }) => {
                                    setTransactions([...(transactions || []), ...data.data])
                                })
                                .finally(() => {
                                    setLoading(false)
                                })
                        }}
                        style={{ marginTop: 40 }}
                    >
                        Carregar mais
                    </Button>
                </Row>
            )}
            {/* {!!individualRating && (
                <Drawer
                    title={
                        <div>
                            <div
                                style={{
                                    fontSize: 17,
                                }}
                            >
                                {individualRating?.contactName} ·{" "}
                                <Tooltip title="Protocolo">
                                    {individualRating?.protocol || "-"}
                                </Tooltip>
                            </div>
                            <span>
                                <span>
                                    <Rate
                                        disabled
                                        defaultValue={individualRating?.rating}
                                        allowHalf
                                    />
                                    <span
                                        style={{
                                            marginLeft: 10,
                                            fontWeight: 700,
                                            fontSize: 16,
                                        }}
                                    >
                                        {individualRating?.rating?.toFixed(1)}
                                    </span>
                                </span>
                            </span>
                        </div>
                    }
                    width={600}
                    onClose={() => setActiveTransaction(null)}
                    visible={!!individualRating}
                    maskClosable
                    closeIcon={<Icon name="fal fa-times" />}
                    bodyStyle={{
                        padding: 0,
                    }}
                    style={{
                        zIndex: 9999,
                    }}
                    extra={
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={`https://go.kinbox.com.br/inbox/all/${individualRating?.customerPlatformId}`}
                        >
                            <Button icon={<i className="fal fa-arrow-up-right-from-square" />}>
                                Abrir
                            </Button>
                        </a>
                    }
                >
                    <Chat
                        id={individualRating?.customerPlatformId}
                        callId={individualRating?.callId}
                        protocol={individualRating?.protocol || "-"}
                        viewOnly
                        limit={100}
                        contactName={individualRating?.contactName}
                    />
                </Drawer>
            )} */}
        </Drawer>
    )
}

export default SmsTransactions
