import React from "react"
import { Redirect } from "react-router-dom"

export default function LegacyUserPath() {
  const { pathname, search } = location

  // Substitui "user" por "auth" no caminho
  const newPathname = pathname.replace("/user/", "/auth/")

  return <Redirect to={`${newPathname}${search}`} />
}
