import { getCookie } from "lib/helper"
import React, { useEffect } from "react"



function deleteCookie(name, domain, path = "/") {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain}`
}

function useRedirected() {
  useEffect(() => {
    const run = async () => {
      try {
        const sessionRedirectedString = getCookie("session-redirected")

        if (!sessionRedirectedString) {
          return
        }

        // Buscar usuário
        const userString = localStorage.getItem("user")
        let localUser: any = null
        try {
          localUser = JSON.parse(userString as any)
        } catch (error) {
          console.log(error)
        }

        // Caso esteja logado
        if (localUser?.id) {
          deleteCookie("session-redirected", "kinbox.com.br")
          return
        }

        // Caso não esteja logado
        fetch("https://api4-staging.kinbox.com.br/v3/redirect-session/" + sessionRedirectedString, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(async (res) => {
          const data = await res.json()
          Object.entries(data).forEach(([key, value]) => {
            localStorage.setItem(key, value as string)
          })

          deleteCookie("session-redirected", "kinbox.com.br")

          // Reload
          window.location.reload()
        })
        // const sessionRedirected = JSON.parse(sessionRedirectedString)
      } catch (error) {
        console.log(error)
      }
    }
    run()
  }, [])
}

export default useRedirected
