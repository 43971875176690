import { sessionsLimitReachedAtom } from "atoms/app.atom"
import { activeConversationSelector, conversationTaskUpdatedAtom } from "atoms/conversation.atom"
import { ReceivedWebhookMonitor, receivedWebhookMonitorAtom } from "atoms/workflow.atom"
import { EventUserWriting, EventWorkspace, workspaceStateAtom, writingAtom } from "atoms/workspace.atom"
import { useStore, useStoreActions, useStoreState } from "hooks"
import { decodeJwt, getHost } from "lib/helper"
import toast from "lib/toast"
import { throttle } from "lodash"
import React, { useCallback, useEffect, useRef } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { Socket, io } from "socket.io-client"
import { session } from "store"

interface useSocketV2Props {
  socketUrl: string
}

let workspaceStateRaw: EventWorkspace = {
  users: {},
}
export let v2Socket: Socket | null = null
function useSocketV2(props: useSocketV2Props) {
  const { workspace, user, logout } = useStore(session)
  const setWorkspaceState = useSetRecoilState(workspaceStateAtom)
  const setSessionsLimitReached = useSetRecoilState(sessionsLimitReachedAtom)
  const setWriting = useSetRecoilState(writingAtom)
  const setReceivedWebhookMonitor = useSetRecoilState(receivedWebhookMonitorAtom)
  const integrationsStore = useStoreState((state) => state.integrations)
  const integrationsActions = useStoreActions((actions) => actions.integrations)
  const [activeConversation, setActiveConversation] = useRecoilState(activeConversationSelector)
  const setConversationTaskUpdated = useSetRecoilState(conversationTaskUpdatedAtom)

  //   const socketRef = useRef<Socket | null>(null)
  const dependenciesRef = useRef<any>(null)

  const saveWorkspaceState = useCallback(() => {
    // Iterar sobre os usuários na estrutura original
    // Iterar sobre as chaves do objeto `users` na estrutura original
    let newWorkspaceState = {
      conversations: {},
      users: workspaceStateRaw?.users,
    }
    for (const userIdKey in workspaceStateRaw?.users) {
      // Obter o usuário correspondente ao userIdKey
      const user = workspaceStateRaw?.users[userIdKey]
      const userId = user.userId

      // Iterar sobre as chaves do objeto `viewingConversationIds` do usuário
      for (const conversationKey in user.viewingConversationIds) {
        const conversationId = user.viewingConversationIds[conversationKey]

        // Se a conversa não estiver presente, inicializá-la
        if (!newWorkspaceState?.conversations?.[conversationId]) {
          newWorkspaceState.conversations[conversationId] = {
            viewingUserIds: {},
          }
        }

        // Marcar que o usuário está visualizando a conversa
        newWorkspaceState.conversations[conversationId].viewingUserIds[userId] = true
      }
    }

    setWorkspaceState(newWorkspaceState)
  }, [setWorkspaceState])

  const throttledSaveWorkspaceState = useCallback(throttle(saveWorkspaceState, 1000), [saveWorkspaceState])

  useEffect(() => {
    dependenciesRef.current = {
      throttledSaveWorkspaceState,
      integrationsStore,
      integrationsActions,
      setReceivedWebhookMonitor,
      activeConversation,
      setConversationTaskUpdated,
    }
  }, [
    activeConversation,
    integrationsActions,
    integrationsStore,
    setConversationTaskUpdated,
    setReceivedWebhookMonitor,
    throttledSaveWorkspaceState,
  ])

  useEffect(() => {
    if (!!v2Socket || !props.socketUrl) {
      return
    }

    // Inicializar o socket
    v2Socket = io(props.socketUrl, {
      query: {
        token: workspace?.token,
        isInspecting:
          getHost()?.subdomain == "view" || getHost()?.subdomain == "partner-inspect" || getHost()?.subdomain == "partner-inspect2",
      },
      transports: ["websocket"], // Utiliza WebSockets como transporte primário
      reconnectionAttempts: 5, // Tentar reconectar 5 vezes
      reconnectionDelay: 5000, // Intervalo de 5000 ms entre tentativas de reconexão
    })

    // setSocket(socketRef.current)

    v2Socket?.on("connect", () => {
      !import.meta.env?.PROD && console.log("🧲 V2 connected")
    })

    v2Socket?.on("workspace:joined", (dto: { workspace: EventWorkspace }) => {
      // !import.meta.env?.PROD && console.log("🧲 V2 workspace:joined", dto)
      //   dependenciesRef.current?.setWorkspaceState(dto.workspace)
      workspaceStateRaw = dto.workspace
      dependenciesRef.current?.throttledSaveWorkspaceState()
    })

    v2Socket?.on(
      "session:limit-reached",
      (dto: {
        sessions: {
          [token: string]: {
            sessionCreatedAt: string
            device: string
            ip: string
            isMobile: boolean
            sessionId: string
          }
        }
      }) => {
        setSessionsLimitReached(dto)
      },
    )

    v2Socket?.on("session:disconnect-from-other-session", (dto: { userId: string; sessionId: string }) => {
      !import.meta.env?.PROD && console.log("🧲 logout", dto)
      if (dto.userId == user?.id) {
        const decoded = decodeJwt(workspace.token)
        if (dto.sessionId === decoded.payload.sessionId) {
          toast.info("Você foi deslogado por outra sessão...")
          setTimeout(() => {
            logout({ notNotifyV0: true })
          }, 3000)
        }
      }
    })
    v2Socket?.on("integration:updated", (dto: { type: "zoho"; data: any }) => {
      console.log("🧲 V2 integration:updated", dto)
      const item = dependenciesRef.current?.integrationsStore.all.find((x) => x.type == dto.type)
      if (item && dto.data.tokenResult) {
        dependenciesRef.current?.integrationsActions.save({
          ...item,
          data: {
            ...item.data,
            tokenResult: { ...item.data, ...dto.data.tokenResult },
          },
        })
      }
    })

    v2Socket?.on("user:updated", (dto: { user: EventWorkspace["users"][0] }) => {
      // !import.meta.env?.PROD && console.log("🧲 V2 user:updated", dto)

      if (dto?.user) {
        workspaceStateRaw = {
          ...workspaceStateRaw,
          users: {
            ...workspaceStateRaw?.users,
            [dto.user.userId]: dto.user,
          },
        }
        dependenciesRef.current?.throttledSaveWorkspaceState()
      }
    })

    v2Socket?.on("user:writing", (dto: EventUserWriting) => {
      setWriting(dto)
    })

    // cadence updated
    v2Socket?.on(
      "task:updated",
      (dto: {
        tasks?: {
          taskId?: string
          dealId?: string
          conversationId?: string
        }[]
      }) => {
        !import.meta.env?.PROD && console.log("🔥 task:updated", dto)
        for (let index = 0; index < (dto.tasks || [])?.length; index++) {
          const task = dto.tasks?.[index]
          if (dependenciesRef.current?.activeConversation?.id == task?.conversationId) {
            dependenciesRef.current?.setConversationTaskUpdated((c) => c + 1)
          }
        }
        // Se devo atualizar conversa ative
      },
    )

    v2Socket?.on("webhook:inbound:monitor", (dto: { socketId: string; received: ReceivedWebhookMonitor }) => {
      console.log("🧲 V2 webhook:inbound:monitor", dto)
      dependenciesRef.current?.setReceivedWebhookMonitor(dto.received)
    })
  }, [props.socketUrl, workspace?.token, setWriting])
}

export default useSocketV2
