import React from "react"
import { message } from "antd"

export const toast = {
  success: (content: string | React.ReactNode, duration: number = 5, props?: any) => {
    return message.success({
      content,
      duration,
      icon: <i className="fas fa-check-circle" />,
      ...props,
    })
  },
  error: (_content: string | React.ReactNode, duration: number = 5, props?: any) => {
    const { prefix, ...rest } = props || {}
    let content = _content
    if (typeof _content === "string") {
      content = _content?.replace?.("HttpException-", "")
    }
    return message.error({
      content: prefix ? (
        <span>
          <span style={{ opacity: 0.65, fontSize: 12 }}>
            {prefix}
            <br />
          </span>
          {content}
        </span>
      ) : (
        content
      ),
      duration,
      icon: <i className="fas fa-do-not-enter" />,
      ...rest,
    })
  },
  info: (content: string | React.ReactNode, duration: number = 5, props?: any) => {
    return message.info({
      content,
      duration,
      icon: <i className="fas fa-info-circle" />,
      ...props,
    })
  },
  warning: (content: string | React.ReactNode, duration: number = 5, props?: any) => {
    return message.warning({
      content,
      duration,
      icon: <i className="fas fa-exclamation-circle" />,
    })
  },
  loading: (content: string | React.ReactNode, duration: number = 5, props: any = { style: { marginTop: 30 } }) => {
    return message.loading({
      content,
      duration,
      icon: <i className="far fa-spinner-third toast-loading" />,
      ...props,
    })
  },
}

export default toast
