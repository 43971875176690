import React, { CSSProperties } from "react"
import queryString from "query-string"
import _ from "lodash"
import config from "config"
import { stores } from "store"
import { MONTHS } from "./dates"
import colorConvert from "color-convert"
import { format, formatDistanceToNowStrict } from "date-fns"
import { pt } from "date-fns/locale"
import { IRolePermissions } from "models/roles"
import { Notification } from "models/notifications"
import { DatePicker, FormInstance, Input, InputNumber, Modal, Select } from "antd"
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html"
// import { IMessage, renderCustomWithCallback } from "pages/inbox/Chat"
import { ICustomField } from "models/customFields"
import { channelsTypes, InputType, ValidationType } from "./constants"
// import locale from "antd/es/date-picker/locale/pt_BR"
import { IChannelTypeIds } from "models/channels"
import { renderCustomWithCallback } from "pages/inbox/Chat/chatUtils"
import { IMessage } from "atoms/conversation.atom"
import request from "./request"
import { endpoints } from "config/endpoints.config"
import cat1 from "assets/images/cats/1.jpeg"
import cat2 from "assets/images/cats/2.jpeg"
import cat3 from "assets/images/cats/3.jpeg"
import cat4 from "assets/images/cats/4.jpeg"
import cat5 from "assets/images/cats/5.jpeg"
import cat6 from "assets/images/cats/6.jpeg"
import cat7 from "assets/images/cats/7.jpeg"
import cat8 from "assets/images/cats/8.jpeg"
import cat9 from "assets/images/cats/9.jpeg"
import ptLocale from "date-fns/locale/pt-BR"

import { IDiceAvatar, themeAtom } from "atoms/app.atom"
import moment from "moment"
import locale from "antd/es/date-picker/locale/pt_BR"
import * as XLSX from "xlsx"
import { IWorkspace } from "models/workspaces"
import { IMember } from "models/members"
import { IGroup } from "models/groups"
import AceEditor from "react-ace"
// import { useRecoilState } from "recoil"
import SimpleCrypto from "simple-crypto-js"
import toast from "./toast"
import dayjs from "dayjs"
import { saveAs } from "file-saver"
import { formatterCurrencyNewWithoutDollarSign, parseDecimal } from "./masks"
import { jsonToSheetOrCSV } from "./export.helper"
// const { parse } = require("json2csv")

export let newSocketWorkspaces: string[] = ["867"]
export var skin = localStorage.getItem("skin") || (getHost()?.subdomain == "mola" || getHost()?.subdomain == "mola2" ? "mola" : "kinbox")
export const isMolaSkin = () => {
  return skin == "mola"
}
export const isPortSkin = () => {
  return skin == "port" || getHost()?.subdomain === "port" || getHost()?.subdomain === "port2"
}

export const getDefaultThemeBySkin = () => {
  if (isMolaSkin()) {
    return "purple"
  }
  if (isPortSkin()) {
    return "port"
  }
  return "light"
}

export const setSkin = (newSkin) => {
  localStorage.setItem("skin", newSkin)
  skin = newSkin
}

const cats = [cat1, cat2, cat3, cat4, cat5, cat6, cat7, cat8, cat9]

export function limit(text: string, maxlength: number) {
  if (typeof text == "string" && text.length >= maxlength) {
    return <span title={text}>{text.slice(0, maxlength) + "..."}</span>
  }
  return text
}

export function replaceFilenameDomain(filename?: string) {
  return (
    filename
      ?.replaceAll("https://api.kinbox.com.br/api/assets/download/", "https://zapiko.s3-sa-east-1.amazonaws.com/")
      .replaceAll("https://kinbox-api-v0-bk-g6pbb.ondigitalocean.app/api/assets/download/", "https://zapiko.s3-sa-east-1.amazonaws.com/")
      .replaceAll(
        "https://kinbox-api-v0-bk2-ev2aq.ondigitalocean.app/api/assets/download/",
        "https://zapiko.s3-sa-east-1.amazonaws.com/",
      ) || ""
  )
}

export function getAsset(fileName?: string): string {
  if (!fileName) return ""
  if (fileName.includes("http")) {
    // "https://api.kinbox.com.br/api/assets/download/",
    return replaceFilenameDomain(fileName)
  }
  // if (fileName.includes("/static/media/") || fileName.includes("data:image/png;base64")) {
  if (fileName.includes("/src/assets") || fileName.includes("data:image/png;base64")) {
    return fileName
  }
  // return stores.general._domain.url + config.endpoints.DOWNLOAD_ASSET + "/" + fileName
  return "https://zapiko.s3-sa-east-1.amazonaws.com/" + fileName
}

const isNilOrBlank = (v: any) => v != null && v != ""

export function timeout(delay: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

export const buildUrlQuery = (endpoint: string, { match, location }: { match: any; location: any }) => {
  return `${match.url}/${endpoint}${location.search}`
}

export function getHoursAndMinutes(minutes: number, options: { returnEmptyString?: boolean } = {}) {
  if (!minutes) return options?.returnEmptyString ? "" : "-"
  const hour = Math.floor(minutes / 60)
  const min = Math.round(minutes % 60)
  return (hour ? hour + "h " : "") + (min + "m")
}

export function getHoursAndMinutesAndSeconds(seconds: number, options: { returnEmptyString?: boolean } = {}) {
  if (!seconds) return options?.returnEmptyString ? "" : "-"

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)

  if (hours) {
    return (hours ? hours + "h " : "") + (minutes ? minutes + "m " : "")
  }

  return (minutes ? minutes + "m " : "") + (secs + "s")
}

export function getDateFormatted(value: any) {
  if (!value) return ""
  const date = new Date(value)
  return `${date?.getDate()} de ${MONTHS[date?.getMonth()]} de ${date?.getFullYear()}`
}

export function getDistanceToNow(dateString: string) {
  if (!dateString) return ""
  return formatDistanceToNowStrict(new Date(dateString), { locale: ptLocale })
}

// Adiciona parametros de query na URL
export function spreadQueryParams({ location, history, newParams }: any) {
  const queryParsed = queryString.parse(location.search)
  return queryString.stringify(_.pickBy({ ...queryParsed, ...newParams }, isNilOrBlank))
}

// Adiciona parametros de query na URL
export function addQueryParams({ history, ...props }: any) {
  // console.log(props, { search: spreadQueryParams(props) })
  history.replace({ search: spreadQueryParams(props) })
}

// Adiciona parametros de query na URL
export function setQueryParams(params: {}, history: any) {
  const paramsString = queryString.stringify(params)
  history.replace({ search: paramsString })
}

// export function getAsset(fileName: any) {
//     if (!fileName) return undefined
//     if (fileName.includes("http")) {
//         return fileName
//     }
//     return stores.general._domain.url + config.api.DOWNLOAD_ASSET + "/" + fileName
// }

export function getHost() {
  const partsLenght = import.meta.env?.PROD && import.meta.env.VITE_ENV !== "staging" ? 3 : 2
  let parts = window.location.host.split(".")
  let subdomain = ""
  if (parts.length >= partsLenght) {
    subdomain = parts[0]
  }
  return { subdomain }
}

export function getUrlBase() {
  return window.location.origin
  // if (isMolaSkin()) return "https://mola.kinbox.com.br"
  // return "https://go.kinbox.com.br"
}

// export function getCompanyUrl(companyName: string) {
//   return `${window.location.protocol}//${companyName}.${window.location.host.replace("www.", "")}`
// }

export function removeLastPath(path: string) {
  return path.substring(0, path.lastIndexOf("/"))
}

export function alphabeticalSort(a: string = "", b: string = "") {
  return (a || "").localeCompare(b || "")
}

export function numberSort(a: any, b: any, key: string) {
  return (a[key] || 0) - (b[key] || 0)
}

// export function dateSort(a: string, b: string) {
//   return +new Date(b) - +new Date(a)
// }

// export function leaf(obj: any, pathParam: string) {
//   const path = pathParam.split(".")
//   var res = obj
//   for (var i = 0; i < path.length; i++) res = res?.[path?.[i]]
//   return res
// }

// export function uploadImageCallBack(file: any) {
//   return new Promise((resolve, reject) => {
//     const xhr = new XMLHttpRequest()
//     xhr.open("POST", "https://api.imgur.com/3/image")
//     xhr.setRequestHeader("Authorization", "Client-ID fea5af9e6e589b7")
//     const data = new FormData()
//     data.append("image", file)
//     xhr.send(data)
//     xhr.addEventListener("load", () => {
//       const response = JSON.parse(xhr.responseText)
//       resolve(response)
//     })
//     xhr.addEventListener("error", () => {
//       const error = JSON.parse(xhr.responseText)
//       reject(error)
//     })
//   })
// }

// export function normFile(e: any) {
//   // console.log('Upload event:', e)
//   if (Array.isArray(e)) {
//     return e
//   }
//   return e && e.fileList
// }

export function capitalizeString(text: string) {
  return text[0].toUpperCase() + text.slice(1)
}

export function capitalizeWords(text?: string) {
  return _.startCase(_.toLower(text || ""))
}

// export function truncate(str: string, n: number) {
//   return str?.length > n ? str.substr(0, n - 1) + "..." : str
// }

// export function getLabelFromEnum(value: any, enumLabel: any) {
//   if (!value) return ""
//   return enumLabel[value]
// }

// export const getKeyValue =
//   <T extends object, U extends keyof T>(obj: T) =>
//   (key: U) =>
//     obj[key]

export function keys<O extends object>(obj: O | undefined): Array<keyof O> {
  return !obj ? [] : (Object.keys(obj) as Array<keyof O>)
}

// export function getBrandColors(color: string) {
//   let brandColorIcon = "#fff"
//   let isDarkBrand = true
//   const brandColorSelectedHsl = colorConvert.hex.hsl(color)
//   if (brandColorSelectedHsl[2] <= 50) {
//     brandColorSelectedHsl[2] += 20
//   } else {
//     brandColorSelectedHsl[2] -= 10
//     brandColorIcon = "#333"
//     isDarkBrand = false
//   }
//   return {
//     brandColor: color,
//     brandColorSelected: "#" + colorConvert.hsl.hex(brandColorSelectedHsl),
//     brandColorIcon,
//     isDarkBrand,
//   }
// }

export function onCellClick(onClick: Function) {
  return {
    onClick: (event: any) => {
      onClick()
    },
  }
}

export function getSettingsColumnProps(path: string, history: any, config?: { _id: boolean }) {
  return {
    onCell: (record: any) => onCellClick(() => history.push(`${path}/${config?._id ? record?._id : record.id}`)),
    className: "pointer",
  }
}

export function formatCompleteDate(date?: string) {
  try {
    if (!date) return ""
    return format(new Date(date), "dd 'de' MMM 'de' yyyy 'às' HH:mm", {
      locale: pt,
    })
  } catch (e) {
    console.log(e)
    return ""
  }
}

export function formatCompleteDateShort(date?: string) {
  try {
    if (!date) return ""
    return format(new Date(date), "MMM dd HH:mm", {
      locale: pt,
    })
  } catch (e) {
    console.log(e)
    return ""
  }
}
export function formatDateAbbr(date: string) {
  if (!date) return "-"
  try {
    return capitalizeString(
      format(new Date(date!), "MMM dd, yyyy", {
        locale: pt,
      }),
    )
  } catch (e) {
    return "-"
  }
}
export function formatDateAbbrMonthYear(date: string) {
  if (!date) return "-"
  try {
    return capitalizeString(
      format(new Date(date!), "MMM yyyy", {
        locale: pt,
      }),
    )
  } catch (e) {
    return "-"
  }
}
export function formatDateDayMonth(date?: string) {
  if (!date) return ""
  return capitalizeString(
    format(dayjs(date).toDate(), "dd 'de' MMMM", {
      locale: pt,
    }),
  )
}
export function formatDateVeryAbbr(date?: string) {
  if (!date) return ""
  return capitalizeString(
    format(new Date(date), "MMM dd", {
      locale: pt,
    }),
  )
}
export function formatDateUrl(date: string) {
  if (!date) return ""
  return format(new Date(date), "dd-MM-yyy")
}
export function formatDateBarTime(date?: string) {
  if (!date) return ""
  return format(new Date(date), "dd/MM/yyy, HH:mm")
}
export function formatDateBarTimeCSVSafe(date?: string) {
  if (!date) return ""
  return format(new Date(date), "dd/MM/yyy HH:mm")
}
export function formatDateBarDayMonthTime(date?: string) {
  if (!date) return ""
  return format(new Date(date), "dd/MM, HH:mm")
}
export function formatDateBarDayMonthTimePrecise(date?: string) {
  if (!date) return ""
  return format(new Date(date), "dd/MM, HH:mm:ss")
}

export function verifyPermission(
  permissionKey: keyof IRolePermissions | undefined,
  { restrictive, ignoreOwner }: { restrictive?: boolean; ignoreOwner?: boolean } = {},
) {
  if (!ignoreOwner && stores?.session?.workspace?.isOwner && restrictive) {
    return false
  }
  if (
    (!ignoreOwner && stores?.session?.workspace?.isOwner && !restrictive) ||
    !permissionKey ||
    stores?.session?.workspace?.role?.permissions?.[permissionKey]
  ) {
    return true
  }
  return false
}

export function verifyAtLeastOnePermission(
  permissionKeys: (keyof IRolePermissions | undefined)[],
  { restrictive }: { restrictive?: boolean } = {},
) {
  for (const permissionKey of permissionKeys) {
    const hasPermission = verifyPermission(permissionKey, { restrictive })
    if (hasPermission) {
      return true
    }
  }
  return false
}

export function showDeleteModal(props: {
  description: string | React.ReactNode
  onConfirm: (...args: any[]) => any
  onCancel?: (...args: any[]) => any
  okText?: string
  cancelText?: string
  title?: string
  zIndex?: number
}) {
  Modal.confirm({
    title: props.title || "Por favor, confirme",
    icon: null,
    // icon: (
    //     <i
    //         className="fal"
    //         style={{
    //             color: "#e74c3c",
    //             float: "left",
    //             marginRight: 16,
    //             fontSize: 20,
    //             marginTop: -4,
    //         }}
    //     />
    // ),
    // content: <div style={{ marginLeft: 38 }}>{props.description}</div>,
    content: props.description,
    okText: props.okText || "Excluir",
    cancelText: props.cancelText || "Cancelar",
    onOk: props.onConfirm,
    onCancel: props.onCancel,
    cancelButtonProps: {
      type: "text",
    },
    zIndex: props.zIndex,
    // okButtonProps: {
    //     danger: true,
    // },
  })
}

export function showInfoModal(props: {
  description: string | React.ReactNode
  onConfirm: (...args: any[]) => any
  okText?: string
  title?: string
}) {
  Modal.confirm({
    title: props.title || "Excluir",
    icon: (
      <i
        className={"fal fa-info-circle"}
        style={{
          color: "rgb(54, 127, 238)",
          float: "left",
          marginRight: 16,
          fontSize: 20,
          marginTop: -4,
        }}
      />
    ),
    content: <div style={{ marginLeft: 38 }}>{props.description}</div>,
    okText: props.okText || "Ok",
    cancelText: "Cancelar",
    onOk: props.onConfirm,
    cancelButtonProps: {
      // className: "normal",
      type: "text",
    },
    // okButtonProps: {
    //     danger: true,
    // },
  })
}

export function getMessageHtml(message: IMessage) {
  let html
  try {
    var converter = new QuillDeltaToHtmlConverter(JSON.parse(message.content), {})
    converter.renderCustomWith((...args) => renderCustomWithCallback(...args, message))
    html = converter.convert()
  } catch (e) {
    html = message.content
    console.log(e)
  }

  return html
}

export interface IInputType {
  type: string
  options?: {
    value: string
    label: string | React.ReactNode
  }[]
  getNode?: (props?: any) => any
}

export function getValueInput(
  values: (IInputType | ICustomField) & {
    htmlInputProps?: any
    json?: { form?: FormInstance<any>; theme?: string; style?: CSSProperties }
  },
  props?: any,
  options?: {
    variables?: boolean
  },
) {
  switch (values?.type) {
    case InputType.CustomNode:
      return (values as any)?.getNode(props)
    case InputType.Number:
      return <InputNumber style={{ width: "100%" }} />
    case InputType.Decimal:
      return (
        <InputNumber
          className="w-full"
          parser={parseDecimal as any}
          formatter={formatterCurrencyNewWithoutDollarSign}
          step={0.01}
          min={0}
        />
      )
    case InputType.Boolean:
      // console.log("values", values)
      return (
        // <Checkbox></Checkbox>
        <Select allowClear>
          <Select.Option value={"1"}>Sim</Select.Option>
          <Select.Option value={"0"}>Não</Select.Option>
        </Select>
      )
    // case InputType.Date:
    //     return (
    //         <DatePicker
    //             placeholder=""
    //             locale={locale}
    //             format="DD-MM-YYYY"
    //             style={{ width: "100%" }}
    //         />
    //     )
    case InputType.Date:
      return <DatePicker placeholder="" locale={locale} format="DD/MM/YYYY HH:mm" showTime style={{ width: "100%" }} />
    case InputType.Textarea:
      return <Input.TextArea rows={6} {...values.htmlInputProps} />
    case InputType.Select:
      return (
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={
            values?.options?.[0]?.["data-search"]
              ? (input, option: any) => {
                  return option?.["data-search"]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              : undefined
          }
        >
          {values?.options?.map((option) => (
            <Select.Option key={option.value} value={option.value} data-search={option["data-search"]}>
              {option?.label || option.value}
            </Select.Option>
          ))}
        </Select>
      )
    case InputType.MultiSelect:
      return (
        <Select
          mode="multiple"
          showSearch
          optionFilterProp="children"
          filterOption={
            values?.options?.[0]?.["data-search"]
              ? (input, option: any) => {
                  return option?.["data-search"]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              : undefined
          }
        >
          {values?.options?.map((option) => (
            <Select.Option key={option.value} value={option.value} data-search={option["data-search"]}>
              {option.label || option.value}
            </Select.Option>
          ))}
        </Select>
      )
    case InputType.JSON:
      if (props?.json) {
        let defaultValue
        try {
          defaultValue = JSON.stringify(JSON.parse(props.json?.value), null, "\t")
        } catch (e) {
          defaultValue = props.json?.value || ""
        }

        return (
          <div>
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.15)",
                borderRadius: 4,
              }}
            >
              <AceEditor
                mode="json"
                theme={props.json?.theme == "dark" ? "terminal" : "github"}
                onChange={(value) => {
                  if (props.json?.onChange) {
                    props?.json.onChange(value)
                  } else {
                    props.json?.form?.setFields([
                      {
                        name: ["customFields", props.json?.name],
                        value: { value },
                      },
                    ])
                  }
                }}
                name={"ACE_EDITOR" + props.json.name}
                defaultValue={defaultValue}
                width="470px"
                style={props.json.style}
              />
            </div>
          </div>
        )
      }
      return <Input {...values.htmlInputProps} />
    case InputType.Text:
    default:
      return <Input {...values.htmlInputProps} />
  }
}

export function diffMinutes(dt2: any, dt1: any) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= 60
  return Math.abs(Math.round(diff))
}

export function getShortName(name: string = "") {
  const nameArray = name?.toLowerCase().split(" ")
  return `${capitalize(nameArray[0])}${nameArray[1] ? " " + nameArray[1] : ""}`
}

export const capitalize = (s: string) => {
  if (typeof s != "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// export const getFilterArrayByObject = (filterObject: any): { label: string; key: string }[] => {
//   const filtersArray: { label: string; key: string }[] = []
//   const addKeyLabel = (key: string, label: string) => {
//     return filtersArray.push({
//       key,
//       label,
//     })
//   }
//   if (filterObject.from) {
//     addKeyLabel(
//       "from",
//       `${formatCompleteDate(filterObject.from[0])} - ${filterObject.from[1] ? formatCompleteDate(filterObject.from[1]) : "Hoje"}`
//     )
//   }
//   if (filterObject.email) {
//     addKeyLabel("email", filterObject.email)
//   }
//   if (filterObject.name) {
//     addKeyLabel("name", filterObject.name)
//   }
//   if (filterObject.phone) {
//     addKeyLabel("phone", filterObject.phone)
//   }
//   if (filterObject.segmentId) {
//     addKeyLabel("segmentId", stores.segment.all.find((segment: any) => segment.id == filterObject.segmentId)?.name)
//   }
//   return filtersArray
// }

export const getChannelType = (channelTypeId: IChannelTypeIds) => {
  return (channelsTypes as any)[channelTypeId]
}

export const exportArray = (arr: any[], fileName: string, config?: { autoSuffix?: boolean; xlsx?: boolean; hideHeader?: boolean }) => {
  try {
    // const worksheet = XLSX.utils.json_to_sheet(arr)
    // const workbook = XLSX.utils.book_new()
    // XLSX.utils.book_append_sheet(workbook, worksheet, fileName.split(".")[0].slice(0, 30))
    const { workbook, csv } = jsonToSheetOrCSV(arr, config)

    if (config?.xlsx) {
      XLSX.writeFile(workbook!, fileName.split(".")[0].slice(0, 30) + ".xlsx")
    } else {
      // // Converter a planilha para CSV
      // const csvContent = XLSX.utils.sheet_to_csv(worksheet, { forceQuotes: true, rawNumbers: true })

      // // Adicionar BOM para corrigir os acentos
      // const bom = "\uFEFF"
      // const csvWithBom = bom + csvContent

      // Criar um Blob com o conteúdo CSV
      const blob = new Blob([csv!], { type: "text/csv;charset=utf-8;" })

      // Salvar o arquivo CSV
      saveAs(blob, `${fileName}.csv`)
    }
  } catch (e) {
    console.error("Erro ao exportar para csv", e)
  }
}

export const parseMention = (text: string) => {
  return text.replace(/@\[(.+)\]\((\d+)\)/, "<span class='mention'>@$1</span>")
}

export const parseLink = (text: string) => {
  return text.replace(
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
    "<a href='$1' target='_blank' class='room_link'>$1</a>",
  )
}

export const parseMentionToNotification = (text: string) => {
  return text.replace(/@\[(.+)\]\((\d+)\)/, "@$1")
}

export const getMentionId = (text: string) => {
  return text.match(/@\[(.+)\]\((\d+)\)/)?.[2]
}

export const getPlanPrices = (props: { licenses: number; whatsapps: number; instagrams: number; discount: number }) => {
  const PRICE = {
    MONTHLY: {
      LICENSE: 79,
      WHATSAPP: 249,
      INSTAGRAM: 50,
    },
    ANNUALLY: {
      LICENSE: 69,
      WHATSAPP: 209,
      INSTAGRAM: 115,
    },
  }
  const totalMonthly =
    PRICE.MONTHLY.LICENSE * props.licenses + PRICE.MONTHLY.WHATSAPP * props.whatsapps + PRICE.MONTHLY.INSTAGRAM * props.instagrams
  const totalAnnually =
    PRICE.ANNUALLY.LICENSE * props.licenses + PRICE.ANNUALLY.WHATSAPP * props.whatsapps + PRICE.ANNUALLY.INSTAGRAM * props.instagrams
  return {
    monthly: {
      licensesValue: PRICE.MONTHLY.LICENSE * props.licenses,
      whatsappsValue: PRICE.MONTHLY.WHATSAPP * props.whatsapps,
      instagramsValue: PRICE.MONTHLY.INSTAGRAM * props.instagrams,
      total: totalMonthly * (1 - props.discount),
      discounted: totalMonthly * props.discount,
    },
    annually: {
      licensesValue: PRICE.ANNUALLY.LICENSE * props.licenses,
      whatsappsValue: PRICE.ANNUALLY.WHATSAPP * props.whatsapps,
      instagramsValue: PRICE.ANNUALLY.INSTAGRAM * props.instagrams,
      total: totalAnnually * (1 - props.discount),
      discounted: totalAnnually * props.discount,
    },
  }
}

export function parseDeltaMessage(message: any) {
  if (!message) return ""
  let parsedMessage
  let msg
  try {
    parsedMessage = JSON.parse(message)
  } catch (e) {
    console.log(e)
    return ""
  }
  if (parsedMessage) {
    msg = parsedMessage.reduce((acc: any, curr: any) => {
      if (typeof curr.insert == "string") {
        acc = acc + " " + curr.insert
      } else if (curr.insert?.image) {
        acc = "🗺 Enviou uma imagem"
      } else if (curr.insert?.audio) {
        acc = "🎵 Enviou um áudio"
      } else if (curr.insert?.media_video) {
        acc = "🎬 Enviou um vídeo"
      } else if (curr.insert?.externalAdReply) {
        acc = "🎯"
      } else if (curr.insert?.contact) {
        acc = "👤  Enviou um contato"
      } else {
        acc = "📦 Enviou um arquivo"
      }
      return acc
    }, "")
  }
  return msg
}

export async function createIntegrationCustomFieldId(
  placeholder: string,
  label: string,
  customFields: ICustomField[],
  customFieldsActions: any,
  options?: any,
) {
  if (customFields.findIndex((custom) => custom.placeholder == placeholder) == -1) {
    await customFieldsActions.save({
      description: "Identificador usado para integração com " + label,
      entity: "contact",
      invisible: true,
      isIntegration: true,
      name: placeholder,
      placeholder: placeholder,
      type: InputType.Text,
      ...options,
    })
  }
}

export function getNoteMessages(conversationId): Promise<string[]> {
  return request
    .get(endpoints.MESSAGE, {
      params: {
        id: conversationId,
        limit: "100",
        offset: "0",
      },
    })
    .then((response) => {
      const messages = response?.data?.data?.map((message: IMessage) => {
        let messageContent = ""
        try {
          messageContent = JSON.parse(message.content)[0].insert
          if (typeof messageContent != "string") {
            const link = messageContent[Object.keys(messageContent)[0]]
            messageContent = `<a href="${link}" target="_blank">${link}</a>`
          }
        } catch {
          console.error("Erro ao parsear mensagem")
        }
        const date = format(new Date(message?.createdAt), "HH:mm, dd/MM/yyyy", {
          locale: pt,
        })
        const name = message.operatorName || message?.Operator?.name || (message.type == 0 ? "Cliente" : "Sistema")
        messageContent = `${name} (${date}): ${messageContent}`
        return messageContent
      })
      return messages
    })
}

interface ISortFolders {
  id: string
  parentId?: string
}

export function sortFolders(items: ISortFolders[]) {
  // Crie um objeto para armazenar os itens indexados por seus IDs.
  const itemsById: { [id: string]: ISortFolders } = {}

  // Crie um objeto para armazenar os itens filhos indexados por seus parentIds.
  const childrenByParentId: { [parentId: string]: ISortFolders[] } = {}

  // Preencha os objetos itemsById e childrenByParentId.
  items.forEach((item) => {
    itemsById[item.id] = item
    if (item.parentId) {
      if (!childrenByParentId[item.parentId]) {
        childrenByParentId[item.parentId] = []
      }
      childrenByParentId[item.parentId].push(item)
    }
  })

  // A função de recursão que irá mapear os itens filhos para seus pais.
  const mapChildren = (parentId: string): ISortFolders[] => {
    const children = childrenByParentId[parentId] || []
    let result: ISortFolders[] = []

    children.forEach((child) => {
      result.push(child)
      result = result.concat(mapChildren(child.id))
    })

    return result
  }

  // Inicie a ordenação a partir dos itens que não têm parentId (itens de nível superior).
  const topLevelItems = items.filter((item) => !item.parentId)
  let sortedItems: ISortFolders[] = []

  topLevelItems.forEach((item) => {
    sortedItems.push(item)
    sortedItems = sortedItems.concat(mapChildren(item.id))
  })

  return sortedItems
  // let sorted: ISortFolders[] = []
  // let bucket = [...items]
  // let count = 0

  // while (bucket.length > 0 && count < 1000) {
  //     count++
  //     // if (count == 999) {
  //     //     console.log("Sorting")
  //     // }
  //     const item = bucket.shift()
  //     if (item?.parentId) {
  //         const index = sorted.findIndex((x) => x.id == item?.parentId)
  //         if (index == -1) {
  //             bucket.push(item)
  //         } else {
  //             sorted = [...sorted.slice(0, index + 1), item, ...sorted.slice(index + 1)]
  //         }
  //     } else {
  //         sorted.push(item!)
  //     }
  //     // console.log('count', count, bucket)
  //     // console.log('sorted', count, sorted)
  // }

  // return sorted
}

export const getCatImage = (seed: string | number) => {
  try {
    return cats[parseInt(seed + "", 10) % cats.length]
  } catch (e) {
    console.log(e)
  }
  return cats[0]
}

export const getDiceAvatar = (seed: string | number, themeAvatar: IDiceAvatar, size = 40) => {
  // return `https://avatars.dicebear.com/api/${themeAvatar}/${seed}.svg`
  return `https://api.dicebear.com/6.x/${themeAvatar}/svg?fontSize=${size}&seed=${seed}`
}

export const formatMinutesToReportTime = (timeMinutes: number = 0) => {
  if (timeMinutes == 0) return "-"
  const hours = Math.floor(timeMinutes / 60)
  const minutes = Math.floor(timeMinutes % 60)
  // const seconds = (timeMinutes % 3600) % 60
  return `${hours.toFixed(0)}h ${minutes.toFixed(0)}m`
}

export const formatSecondsToMinutesSeconds = (timeSeconds: number = 0) => {
  if (timeSeconds == 0) return "0m 0s"
  const minutes = Math.floor(timeSeconds / 60)
  const seconds = Math.floor(timeSeconds % 60)
  // const seconds = (timeMinutes % 3600) % 60
  return `${minutes.toFixed(0)}m ${seconds.toFixed(0)}s`
}

export const formatSecondsToMinutesSecondsShort = (timeSeconds: number = 0) => {
  if (timeSeconds == 0) return "0:00"
  const minutes = Math.floor(timeSeconds / 60)
  const seconds = Math.floor(timeSeconds % 60)
  let secondFormatted = seconds.toFixed(0) + ""
  if (secondFormatted.length === 1) {
    secondFormatted = "0" + secondFormatted
  }
  // const seconds = (timeMinutes % 3600) % 60
  return `${minutes.toFixed(0)}:${secondFormatted}`
}

export function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const encodeStringToBrackets = (str: string) => {
  return str.replace(/{{/g, "[[[").replace(/}}/g, "]]]")
}

const decodeStringFromBrackets = (str: string) => {
  return str.replace(/\[\[\[/g, "{{").replace(/\]\]\]/g, "}}")
}
const decodeTripleBracketToSingle = (str: string) => {
  return str.replace(/\[\[\[/g, "[").replace(/\]\]\]/g, "]")
}

export function replaceTemplateVariables(text: string = "", variables: string[] = []) {
  return decodeTripleBracketToSingle(
    variables.reduce((acc, val, index) => {
      return acc.replace(`[[[${index + 1}]]]`, val)
    }, encodeStringToBrackets(text)),
  )
}

export function buildSocketUrl(workspace: IWorkspace, context: { _domain: any }) {
  let env = JSON.parse(localStorage.getItem("nanui-env")!)
  const workspaceId = (workspace?.id || workspace?.workspaceId) as string
  const isBigWorkspace = newSocketWorkspaces.includes(workspaceId)

  let domain = isBigWorkspace
    ? env?.socketUrl || "https://notificator.kinbox.com.br" //= context?._domain.url.slice(0, context?._domain.url.length - 4)
    : env?.socketUrlB || "https://kinbox-api-v0-schedulers-fqnf8.ondigitalocean.app"

  if (!newSocketWorkspaces.includes(workspaceId + "") && import.meta.env?.PROD && import.meta.env.VITE_ENV !== "staging") {
    // if (!["867"].includes(workspaceId + "")) {
    domain = "https://kinbox-api-v0-notificator-v2-y4mrw.ondigitalocean.app"
    return `${domain}/chat/workspace/${workspace?.workspaceId}?token=${workspace?.token}`
  }

  if (!(import.meta.env?.PROD && import.meta.env.VITE_ENV !== "staging")) {
    domain = context?._domain.socketUrl
  }

  // if (env?.key == "production" || import.meta.env?.PROD) {
  //   // domain = "https://kinbox-api-v0-schedulers-fqnf8.ondigitalocean.app"
  //   domain = "https://notificator.kinbox.com.br"
  // } else {
  //   domain = context?._domain.socketUrl
  // }
  // else {
  //   domain = "https://kinbox-api-v0-staging-notificat-9nl4h.ondigitalocean.app"
  //   // domain = "https://notificator.kinbox.com.br"
  // }

  // const privateCrypto = new SimpleCrypto(import.meta.env?.VITE_CUSTOM_CRYPTO_KEY || "123456")
  const privateCrypto = new SimpleCrypto("123456")
  const g_tracking_id = privateCrypto.encrypt(workspace?.token || "")
  const work_block = btoa(workspace?.token || "")

  // console.log("SOCKET", `${domain}/chat/workspace/${workspace?.workspaceId}?g_tracking_id=${encodeURI(g_tracking_id)}`)

  return `${domain}/chat/workspace/${workspace?.workspaceId}?token=${workspace?.token}`
  // return `${domain}/chat/workspace/${workspace?.workspaceId}?g_tracking_id=${encodeURI(g_tracking_id)}&work_block=${encodeURI(work_block)}`
}

export function buildSocketUrlV2(workspace: IWorkspace, context: { _domain: any }) {
  let env = JSON.parse(localStorage.getItem("nanui-env")!)
  let domain = (env?.v2SocketUrl as string) || "https://notificator-v2.kinbox.com.br/workspace"
  return domain
}

export function getMembersOfSameGroups(memberId: string, members: IMember[], groups: IGroup[]) {
  let memberGroups = groups.filter((group) => group.members.includes(memberId))

  return members.filter((member) => {
    return memberGroups.some((group) => group.members.includes(member.id))
  })
}

// Only members that it has permission to assign to
export function getMembersToAssign(memberId: string, members: IMember[], groups: IGroup[], permissionString?: keyof IRolePermissions) {
  const restrictedToSameGroup = verifyPermission(permissionString || "chat_assign_restricted", {
    restrictive: true,
  })

  if (restrictedToSameGroup) {
    return getMembersOfSameGroups(memberId, members, groups)
  }

  return members.filter((member) => member.isActive)
}

// Only members that it has permission to assign to
export function getGroupsToMove(memberId: string, groups: IGroup[]) {
  const restrictedToSameGroup = verifyPermission("chat_group_restricted", {
    restrictive: true,
  })

  if (restrictedToSameGroup) {
    let memberGroups = groups.filter((group) => group.members.includes(memberId))

    return memberGroups
  }

  return groups
}

// Validate if value is valid
export function validateValue(value: string, type: string, maskOrRegex?: string) {
  try {
    switch (type) {
      case ValidationType.EMAIL:
        return isValidEmail(value)
      case ValidationType.CELLPHONE:
        return isValidPhone(value)
      case ValidationType.CPF:
        return isValidCpf(value)
      case ValidationType.REGEX:
        return validarRegex(value, maskOrRegex || "")
      case ValidationType.MASK:
        return isValidMask(value, maskOrRegex || "")
      default:
        return true
    }
  } catch (e) {
    console.log(e)
    return { isValid: false }
    // throw e
  }
}

const isValidEmail = (dataToValidate) => {
  try {
    const re = /\S+@\S+\.\S+/ // /^\S+@\S+$/

    return typeof dataToValidate == "string" && dataToValidate.length > 5 && dataToValidate.length < 100 && re.test(dataToValidate)
  } catch (e) {
    return false
  }
}

export function isValidPhone(celular) {
  if (!celular) return false

  const originalLength = celular.length
  celular = celular.replace(/[^\d]/g, "")

  return originalLength == celular.length && celular.length >= 10 && celular.length <= 13
}

export function isValidCpf(cpf) {
  // remove caracteres que não sejam números
  cpf = cpf.replace(/[^\d]/g, "")

  // verifica se o CPF tem 11 dígitos
  if (cpf.length != 11) {
    return false
  }

  // verifica se todos os dígitos são iguais
  const repetidos = /(.)\1{10}/.test(cpf)
  if (repetidos) {
    return false
  }

  // calcula os dígitos verificadores
  let soma = 0
  let resto
  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }
  resto = (soma * 10) % 11
  if (resto == 10 || resto == 11) {
    resto = 0
  }
  if (resto != parseInt(cpf.substring(9, 10))) {
    return false
  }

  soma = 0
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }
  resto = (soma * 10) % 11
  if (resto == 10 || resto == 11) {
    resto = 0
  }
  if (resto != parseInt(cpf.substring(10, 11))) {
    return false
  }

  // se passar por todas as validações, retorna true
  return true
}

// function validarRegex(value: string, regex: string): boolean {
//     const re = new RegExp(regex)
//     console.log(11111, value, regex, re, re.test(value))
//     return re.test(value)
// }

function validarRegex(text: string, regexStr: string): boolean {
  let regex: RegExp
  if (regexStr.startsWith("/") && regexStr.lastIndexOf("/") != 0) {
    const lastSlashIndex = regexStr.lastIndexOf("/")
    const pattern = regexStr.slice(1, lastSlashIndex)
    const flags = regexStr.slice(lastSlashIndex + 1)
    regex = new RegExp(pattern, flags)
  } else {
    regex = new RegExp(regexStr)
  }
  // console.log("VALIDAR", text, regexStr, regex, regex.test(text))
  return regex.test(text)
}

type MaskChar = "A" | "9" | "*"

function isDigit(char) {
  return char >= "0" && char <= "9"
}
function isAlpha(char) {
  return (char >= "A" && char <= "Z") || (char >= "a" && char <= "z")
}

function isValidMask(text, mask) {
  try {
    let textIndex = 0
    let maskIndex = 0

    while (maskIndex < mask.length) {
      const maskChar = mask.charAt(maskIndex)
      const textChar = text.charAt(textIndex)

      if (textIndex >= text.length) {
        return false
      }

      if (maskChar == "A") {
        if (!isAlpha(textChar)) {
          return false
        }
      } else if (maskChar == "9") {
        if (!isDigit(textChar)) {
          return false
        }
      } else if (maskChar == "*") {
        if (!isAlpha(textChar) && !isDigit(textChar)) {
          return false
        }
      } else if (maskChar != textChar) {
        return false
      }

      textIndex++
      maskIndex++
    }

    return textIndex == text.length
  } catch (error) {
    return false
  }
}
// function isValidMask(text: string, mask: string): boolean {
//     let textIndex = 0
//     let maskIndex = 0

//     while (maskIndex < mask.length) {
//         const maskChar: string = mask.charAt(maskIndex)
//         const textChar: string = text.charAt(textIndex)

//         if (textIndex >= text.length) {
//             return false
//         }

//         switch (maskChar as MaskChar) {
//             case "A":
//                 if (!textChar.match(/[a-zA-Z]/)) {
//                     return false
//                 }
//                 break
//             // case "1":
//             case "9":
//                 if (!textChar.match(/[0-9]/)) {
//                     return false
//                 }
//                 break
//             case "*":
//                 if (!textChar.match(/[a-zA-Z0-9]/)) {
//                     return false
//                 }
//                 break
//             default:
//                 if (maskChar != textChar) {
//                     return false
//                 }
//         }

//         textIndex++
//         maskIndex++
//     }

//     return textIndex == text.length

//     // Exemplo de uso:
//     // const text = "AB1234CD"
//     // const mask = "AA9999**"
//     // console.log(isValidText(text, mask)) // Saída: true

//     // 'A': representa uma letra maiúscula ou minúscula (a-zA-Z)
//     // '9': representa um dígito numérico (0-9)
//     // '*': representa um caractere alfanumérico (a-zA-Z0-9)
// }
export function removeKeysFromObject(obj, keys) {
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i]
    if (obj.hasOwnProperty(key)) {
      delete obj[key]
    }
  }
  return obj
}

export function transformValueObjectWithFunc(obj, func) {
  var result = {}

  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = func(obj[key])
    }
  }

  return result
}

// export function sizeOfObject(object) {
//   const jsonString = JSON.stringify(object)
//   const sizeInBytes = new Blob([jsonString], { type: "application/json" }).size

//   // Use a linha abaixo para o cálculo de acordo com o Sistema Internacional de Unidades
//   // const sizeInMB = sizeInBytes / 1_000_000;

//   // Ou use a linha abaixo para o cálculo de acordo com o padrão usado em contextos de computação
//   const sizeInMB = sizeInBytes / 1_048_576

//   return sizeInMB + "mb"
// }

export async function requestMicrophonePermission() {
  try {
    // Solicita acesso ao microfone
    const stream = await navigator?.mediaDevices?.getUserMedia?.({ audio: true })

    // Se a permissão for concedida, o stream do microfone será retornado e podemos fechá-lo
    stream?.getTracks?.().forEach((track) => track.stop())
    console.log("Permissão do microfone concedida.")
    return true
  } catch (error: any) {
    console.error("Erro ao acessar o microfone:", error)
    // Verificar se o erro é devido ao acesso bloqueado
    if (error.name == "NotAllowedError") {
      toast.error("Acesso ao microfone foi bloqueado. Por favor, ajuste as configurações do seu navegador para continuar.")
      throw new Error("Microphone access blocked")
    }
    return false
  }
}

export function base64Decode(str) {
  // Decodificar a string de Base64 para uma string binária
  var binaryString = atob(str)
  var bytes = new Uint8Array(binaryString.length)
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  // Converter os bytes para uma string UTF-8
  var decoder = new TextDecoder("utf-8")
  return decoder.decode(bytes)
}

export function removeAccents(texto) {
  return texto?.normalize?.("NFD").replace(/[\u0300-\u036f]/g, "")
}

export function seedStringToNumber(str: string) {
  let hash = 0
  if (str.length == 0) return hash
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

export const getColorPaletteByIndex = (seed: number | string, palette: any[]) => {
  const parsedIndex = Math.abs(seedStringToNumber(String(seed)))
  const colorIndex = isNaN(parsedIndex) ? Math.round(Math.random() * palette.length) : parsedIndex
  return palette[colorIndex % palette.length]
}

export function convertLinksToAnchors(text, options?: { className: string }) {
  var urlPattern = /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
  return text.replace(urlPattern, `<a href="$1" class="${options?.className || ""}" target="_blank" rel="noreferrer noopener">$1</a>`)
}

export function extractMentions(text: string) {
  const mentions: { name: string; id: string; key: string }[] = []
  // const mentionPattern = /@([a-zA-ZáÁéÉíÍóÓúÚâÂêÊîÎôÔûÛäÄëËïÏöÖüÜçÇñÑ_]+)_([0-9]+)/g
  // const mentionPattern = /@([^_]+)_([0-9]+)/g;
  // const mentionPattern = /@([a-zA-Z_]+)_([0-9]+)/g
  const mentionPattern = /@(.*?)_([0-9]+)/g
  let match

  while ((match = mentionPattern.exec(text)) !== null) {
    let nameWithUnderscores = match[1]
    const id = match[2]
    const key = match[0]

    // Substituir sublinhados por espaços e remover sublinhados extras no início ou fim do nome
    let name = nameWithUnderscores.replace(/_/g, " ").trim()

    mentions.push({ name, id, key })
  }

  return mentions
}

export function xlsToCSV(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      try {
        // const data = new Uint8Array(e.target.result)
        // const workbook = XLSX.read(data, { type: "array" })
        // const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        // const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        // const firstRow = jsonData[0]
        const data = e.target.result
        const workbook = XLSX.read(data, { type: "binary" })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const csv = XLSX.utils.sheet_to_csv(worksheet, { rawNumbers: true })
        resolve(csv)
      } catch (error) {
        reject(error)
      }
    }

    reader.readAsBinaryString(file)
  })
}

export const getPhoneVariationsWithDDI = (phone) => {
  const BRAZIL_COUNTRY_CODE = "55"
  const MIN_LENGTH_WITHOUT_COUNTRY_CODE = 10
  const COUNTRY_CODE_LENGTH = 2

  try {
    // Verificar se o telefone é válido
    if (!phone || phone.replace(/\D/g, "").length < MIN_LENGTH_WITHOUT_COUNTRY_CODE) {
      return { shortPhone: null, longPhone: null }
    }

    // Remover caracteres não numéricos e adicionar código do país se necessário
    let normalizedPhone = phone.replace(/\D/g, "")
    if (normalizedPhone.length < 12 && !normalizedPhone.startsWith(BRAZIL_COUNTRY_CODE)) {
      normalizedPhone = BRAZIL_COUNTRY_CODE + normalizedPhone
    }

    // Criar versões curta e longa do número
    let shortPhone, longPhone
    if (normalizedPhone.length >= COUNTRY_CODE_LENGTH + MIN_LENGTH_WITHOUT_COUNTRY_CODE + 1) {
      // Número já inclui o dígito 9 após o DDD
      shortPhone = normalizedPhone.slice(0, COUNTRY_CODE_LENGTH + 2) + normalizedPhone.slice(COUNTRY_CODE_LENGTH + 3)
      longPhone = normalizedPhone
    } else {
      // Adicionar o dígito 9 após o DDD para o formato longo
      shortPhone = normalizedPhone
      longPhone = normalizedPhone.slice(0, COUNTRY_CODE_LENGTH + 2) + "9" + normalizedPhone.slice(COUNTRY_CODE_LENGTH + 2)
    }

    return { shortPhone, longPhone }
  } catch (error) {
    console.error("Error formatting phone number:", error)
    return { shortPhone: null, longPhone: null }
  }
}

/*
 * Transforma uma string com spintaxe em uma string aleatória
 * {Oi|Ola}, seja bem-vindo! Como {posso|podemos} te ajudar?
 */
export function spintaxe(str) {
  return str.replace(/\{\{(.*?)\}\}/g, (match) => {
    const options = match.slice(2, -2).split("|")
    return options[Math.floor(Math.random() * options.length)]
  })
}

export const getNameLastname = (fullName?: string) => {
  if (!fullName) return ""
  const allNames = fullName.trim().split(" ")
  const firstName = allNames[0]
  const lastName = allNames?.length > 1 ? allNames[allNames.length - 1] : ""
  return `${allNames[0]}${lastName ? " " + lastName : ""}`
}

export async function convertBlobUrlToFile(blobUrl: string, fileNameArg?: string) {
  try {
    // Extrair o nome do arquivo do URL do Blob
    const fileName = fileNameArg || blobUrl.split("/").pop()!

    // Acessar o objeto Blob usando fetch com async/await
    const response = await fetch(blobUrl)
    const blob = await response.blob()

    // Criar um objeto File a partir do Blob
    const file = new File([blob], fileName, { type: blob.type })

    return file
  } catch (error) {
    console.error("Erro ao converter Blob para File:", error)
    throw error // Lançar o erro para ser tratado por quem chama a função
  }
}

export const buildNotificationFromOperadorIds = (dto: {
  operatorIds: string[]
  members: IMember[]
  user: IMember
  payload: Partial<Notification>
}) => {
  const { operatorIds, members, user, payload } = dto
  let array: Partial<Notification>[] = []
  for (let index = 0; index < operatorIds.length; index++) {
    const operatorId = operatorIds[index]
    const memberTarget = members.find((m) => m.id == operatorId)
    array.push({
      authorAvatar: user?.avatar,
      authorName: getNameLastname(user?.name),
      authorId: +user?.id,
      ...payload,
    })
  }
}

export function formatMilliseconds(milliseconds?: number) {
  if (!milliseconds) return ""

  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  const secondsRemainder = seconds % 60
  const minutesRemainder = minutes % 60
  const hoursRemainder = hours % 24

  let result = ""

  if (days > 0) {
    result += `${days} dia${days > 1 ? "s" : ""}`
    if (hoursRemainder > 0) {
      result += ` e ${hoursRemainder} hora${hoursRemainder > 1 ? "s" : ""}`
    }
  } else if (hours > 0) {
    result += `${hours} hora${hours > 1 ? "s" : ""}`
    if (minutesRemainder > 0) {
      result += ` e ${minutesRemainder} minuto${minutesRemainder > 1 ? "s" : ""}`
    }
  } else if (minutes > 0) {
    result += `${minutes} minuto${minutes > 1 ? "s" : ""}`
    if (secondsRemainder > 0) {
      result += ` e ${secondsRemainder} segundo${secondsRemainder > 1 ? "s" : ""}`
    }
  } else {
    result = `${seconds} segundo${seconds > 1 ? "s" : ""}`
  }

  return result
}

function base64UrlToBase64(base64Url) {
  return base64Url
    .replace(/-/g, "+") // substitui '-' por '+'
    .replace(/_/g, "/") // substitui '_' por '/'
}

// Função para codificar JSON para Base64
export function encodeJsonToBase64(jsonObj) {
  const jsonString = JSON.stringify(jsonObj) // Converte JSON para string
  return btoa(encodeURIComponent(jsonString)) // Codifica para Base64
}

// Função para decodificar Base64 para JSON
export function decodeBase64ToJson(base64String) {
  const jsonString = decodeURIComponent(atob(base64String)) // Decodifica para string
  return JSON.parse(jsonString) // Converte para JSON
}

export function decodeJwt(jwt) {
  const parts = jwt.split(".")
  if (parts.length !== 3) {
    throw new Error("JWT inválido.")
  }

  const header = JSON.parse(atob(base64UrlToBase64(parts[0])))
  const payload = JSON.parse(atob(base64UrlToBase64(parts[1])))

  return { header, payload }
}

export function verifyWhatsappGoogleAd(source_url: string) {
  return source_url?.includes?.("utm_source=google") || source_url?.includes?.("utm_source=whatsapp") || source_url?.includes?.("gclid=")
}

export function sanitizeHeader(text: string) {
  return text?.replace(/[^\x20-\x7E]/g, "")
}

export function closeFullscreen() {
  try {
    if ((document as any).exitFullscreen) {
      ;(document as any).exitFullscreen()
    } else if ((document as any).mozCancelFullScreen) {
      // Para Firefox
      ;(document as any).mozCancelFullScreen()
    } else if ((document as any).webkitExitFullscreen) {
      // Para Chrome, Safari e Opera
      ;(document as any).webkitExitFullscreen()
    } else if ((document as any).msExitFullscreen) {
      // Para Internet Explorer/Edge
      ;(document as any).msExitFullscreen()
    }
  } catch {}
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export interface WaveformConfig {
  canvas: HTMLCanvasElement | null
  analyser: AnalyserNode | null
  barWidth?: number
  barSpacing?: number
  animationIdRef: React.MutableRefObject<number | null>
  barBuffer: React.MutableRefObject<number[]>
  frameCounter?: number
  widthBuffer: any
}

export const drawWaveform = (config: WaveformConfig) => {
  const { canvas, analyser, barWidth = 5, barSpacing = 3, animationIdRef, barBuffer, widthBuffer } = config

  if (!canvas || !analyser) return

  const ctx = canvas.getContext("2d")
  if (!ctx) return

  // Controle de atualização lenta
  const updateInterval = 4 // Atualizar uma nova barra a cada 4 frames
  let frameCounter = config.frameCounter || 4

  const bufferLength = analyser.frequencyBinCount
  const dataArray = new Uint8Array(bufferLength)

  if (frameCounter % updateInterval === 0) {
    analyser.getByteFrequencyData(dataArray)

    // Calcular a altura média para a barra
    const avgFrequency = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length

    // Normalizar a altura e aplicar o fator de escala
    const scaleFactor = 2
    const normalizedHeight = Math.max((avgFrequency / 255) * (canvas.height / 2) * scaleFactor, 2)

    barBuffer.current.push(normalizedHeight)
    if (barBuffer.current.length > canvas.width / (barWidth + barSpacing)) {
      barBuffer.current.shift() // Remove a barra mais antiga
    }

    // Adiciona a largura inicial mínima no buffer
    widthBuffer.current.push(2) // Largura inicial
    if (widthBuffer.current.length > barBuffer.current.length) {
      widthBuffer.current.shift()
    }
  }

  frameCounter += 1

  // Limpar o canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height)

  // Desenhar as barras com animação de crescimento
  ctx.fillStyle = "#9b9fa5"
  const cornerRadius = Math.min(barWidth / 2, 5) // Ajusta o raio das bordas
  let x = canvas.width - barBuffer.current.length * (barWidth + barSpacing)

  for (let i = 0; i < barBuffer.current.length; i++) {
    const targetHeight = barBuffer.current[i]
    const centerY = canvas.height / 2

    // Gradualmente aumenta a largura
    widthBuffer.current[i] = Math.min(
      widthBuffer.current[i] + 0.1, // Incremento na largura
      barWidth,
    )

    const currentWidth = widthBuffer.current[i]
    const yTop = centerY - targetHeight
    const yBottom = centerY + targetHeight

    // Desenhar barra com bordas arredondadas
    ctx.beginPath()
    ctx.moveTo(x + cornerRadius, yTop) // Canto superior esquerdo
    ctx.arcTo(x + currentWidth, yTop, x + currentWidth, yBottom, cornerRadius) // Borda superior direita
    ctx.arcTo(x + currentWidth, yBottom, x, yBottom, cornerRadius) // Borda inferior direita
    ctx.arcTo(x, yBottom, x, yTop, cornerRadius) // Borda inferior esquerda
    ctx.arcTo(x, yTop, x + currentWidth, yTop, cornerRadius) // Borda superior esquerda
    ctx.closePath()
    ctx.fill()

    x += barWidth + barSpacing
  }

  config.frameCounter = frameCounter // Salva o contador de frames

  animationIdRef.current = requestAnimationFrame(() => drawWaveform(config))
}

export function setCookie(name, value, days, domain) {
  let expires = ""
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/; domain=${domain}`
}

export function getCookie(name) {
  const cookieString = document.cookie
  const cookies = cookieString.split("; ")
  for (const cookie of cookies) {
    const [key, value] = cookie.split("=")
    if (key === name) {
      return decodeURIComponent(value)
    }
  }
  return null // Retorna null se o cookie não existir
}
