import { endpoints } from "config/endpoints.config"
import { GenericResource, IGenericModel, generic } from "models/generic"

export interface IRolePermissions {
  scope_all: boolean
  scope_group: boolean
  scope_group_restrict: boolean
  scope_group_limited: boolean
  scope_user: boolean
  scope_limited: boolean
  chat_respond: boolean
  chat_respond_my: boolean
  chat_private_note: boolean
  chat_delete_message: boolean
  chat_active_chat: boolean
  chat_active_only_not_assigned: boolean
  chat_active_only_not_assigned_real: boolean
  chat_active_only_not_assigned_contact: boolean
  chat_resolve: boolean
  chat_reopen: boolean
  chat_tag: boolean
  // chat_assign_self: boolean
  chat_assign: boolean
  chat_assign_restricted: boolean
  chat_group: boolean
  chat_group_restricted: boolean
  chat_bot: boolean
  chat_schedule: boolean
  chat_transfer: boolean
  chat_hide_messages: boolean
  chat_hide_messages_without_accept: boolean
  chat_hide_tags: boolean
  chat_remove_tags: boolean
  chat_delete_self_message: boolean
  chat_cannot_share: boolean
  chat_cannot_block: boolean
  chat_cannot_sync_history: boolean
  chat_cannot_export_csv: boolean
  chat_viewers_invisible: boolean
  chat_viewers_disabled: boolean
  chat_cannot_see_contact_notes: boolean
  chat_cannot_see_contact_info: boolean
  chat_cannot_see_session_info: boolean
  chat_cannot_see_contact_integrations: boolean
  manage_contacts: boolean
  contact_cannot_export: boolean
  contact_properties: boolean
  contact_merge: boolean
  contact_hide_phone: boolean
  contact_not_change_phone: boolean
  contact_can_see_private: boolean
  team_chat: boolean
  manage_billing: boolean
  manage_reports: boolean
  manage_reports_by_groups: boolean
  manage_reports_by_myself: boolean
  reports_cannot_export: boolean
  manage_campaigns: boolean
  forbid_campaign_whatsapp_chat: boolean
  manage_segments: boolean
  // manage_segments_public: boolean
  manage_workspace: boolean
  manage_workflow: boolean
  manage_roles: boolean
  manage_customFields: boolean
  manage_channels: boolean
  manage_users: boolean
  manage_schedule_messages: boolean
  manage_groups: boolean
  manage_businessHours: boolean
  manage_knowledge_bases: boolean
  manage_pipelines: boolean
  manage_products: boolean
  manage_tags: boolean
  manage_quick_phrases: boolean
  manage_auto_assign: boolean
  manage_automations: boolean
  manage_bots: boolean
  manage_goals: boolean
  can_block_bots: boolean
  manage_webhook: boolean
  manage_plugins: boolean
  manage_integrations: boolean
  manage_api: boolean
  manage_audit: boolean
  chat_send_message_only_on_business_hour: boolean
  chat_send_message_only_on_business_hour_id: string
  chat_send_message_only_on_channels: boolean
  chat_send_only_allowed_words: boolean
  chat_send_message_only_on_channels_ids: string
  user_cant_be_unavailable: boolean
  user_cant_change_availability: boolean
  user_cant_change_profile: boolean
  rooms_access_dm_only_groups: boolean
  user_cant_see_channel_notifications: boolean
  rooms_access_forbidden: boolean
  only_can_see_channels_if_in_channel_group: boolean

  assistant_correct_portuguese: boolean
  assistant_expand: boolean
  assistant_summarize: boolean
  assistant_simplify: boolean
  assistant_rephrase: boolean
  assistant_translate_to_english: boolean
  assistant_translate: boolean
  assistant_change_tone: boolean
  assistant_generate_texts: boolean
  assistant_summarize_call: boolean
  skip_read_conversation: boolean
  not_show_expiring_banner: boolean
  not_auto_unavailable: boolean
  not_auto_offline: boolean

  partner_panel_access: boolean
  partner_inspect_clients: boolean
  partner_inspect_clients_advanced: boolean
  partner_invoices_access: boolean
  partner_reset_operator_password: boolean

  manage_deals: boolean
  deal_scope_not_assigned_to_other: boolean
  deal_scope_not_unassigned: boolean
  deal_scope_not_other_groups: boolean
  deal_scope_not_ungrouped: boolean
  deal_cannot_delete: boolean
  deal_cannot_export: boolean
  deal_cannot_rollback_stage: boolean
  deal_cannot_create_multiple_open_deals_contact: boolean
  deal_scope_conversation: boolean

  manage_tasks: boolean
  task_scope_assigned_to_other: boolean
  task_scope_conversation: boolean
  avaliable_only_on_business_hour: boolean
}

export interface IRole extends GenericResource {
  id: string
  name: string
  updatedAt: string
  createdAt: string
  description: string
  permissions: Partial<IRolePermissions>
}

export const roles: IGenericModel<IRole> = {
  ...generic(endpoints.ROLES),
}
